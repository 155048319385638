/* MIXINS */
.size(@value; @unit:rem){
  font-size:unit(@value/@font-size, @unit);
}
.clearfix(){
  &:after{
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

.clearlist(){
  margin:0;
  padding:0;
  list-style:none;
  li{
    padding:0;
    margin:0;
  }
}

.animate(){
  .lh-transition(all 200ms ease-in-out);
}




.mobile-navigation(){
  position:fixed;
  top:0;
  right:0;
  left:0;
  z-index:20000;
  display:none;

  .mobile-navigation-opened & {
    display:block;
  }

  @media @breakpoint-smartphone{
    display:block !important;
  }

  &:before{
    background:#fff;
    height:60px;
    box-shadow:0px 0px 5px rgba(0,0,0,0.3);
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    z-index:10;
  }

  &:after{
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:1;
    background:rgba(0,0,0,0.6);
  }

  .mobile-navigation-opened &{
    height:100%;
  }

  .mobile-navigation-container{
    max-height: 100%;
    overflow:auto;
    position:absolute;
    top:0;
    left:0;
    right:0;
    z-index:5;
    display:none;

    .mobile-navigation-opened &{
      display:block;
    }
  }

  > span{
    position:absolute;
    top:0;
    right:0;
    width:40px;
    height:40px;
    background:#fff;
    font-size:20px;
    line-height:40px;
    text-align: center;
    z-index:100;
  }
}

.parallax(){
  .lh-perspective(100px);
  .lh-transform-style(preserve-3d);
  .lh-transform-origin(50% 50%);
  //height: 100%;
  //overflow-y: scroll;
  overflow-x: hidden;

  @media @breakpoint-smartphone{
    .lh-perspective(none);
    .lh-transform-style(inherit);
  }
}


.table-row(){
  .clearlist;
  display:table;
  width:100%;
  table-layout: fixed;

  @media @breakpoint-smartphone{
    .lh-display(flex);
    .lh-flex-direction(column);
  }

  > div,
  > li{
    display:table-cell;
    vertical-align: middle;
    position:relative;
    text-align: center;

    @media @breakpoint-smartphone{
      display: block;
    }
  }
  img{
    display:block;
    width:100%;
    max-width:100%;
  }
}


.block(){
  display:block;
  width:auto;
  float:none;
  margin:0;
}

.masonry() {
  .lh-display(flex);
  .lh-flex-direction(column);
  .lh-flex-wrap(wrap);
  height: 100vw;
  font-size: 0;
}

.flex-grid(){
  .clearlist;
  .lh-display(flex);
  .lh-flex-direction(row);
  //.lh-justify-content(center);
  .lh-align-items(stretch);
  .lh-flex-wrap(wrap);

  & > div{
    .lh-flex-grow(1);
  }
}


.flex-row(){
  .clearlist;
  .lh-display(flex);
  .lh-flex-direction(row);
  //.lh-justify-content(center);
  .lh-align-items(stretch);
}

.middle(){
  position:relative;
  top:50%;
  .lh-transform(translateY(-50%));
  display:block;
}







/* WEEBOX */
.common-blog(@common-blog-post-gutter:0px){

  > .wrapper-content{
    .flex-grid();
  }

  figure{
    img{
      width:100%;
    }
  }

  .common-blog-header{
    width:100%;
  }

  .common-blog-pagination{
    width: 60%;
    .lh-order(3);
  }

  .common-blog-listing,
  .common-blog-article{
    .clearlist;
    width: 60%;
    padding: 0;
    .lh-order(1);
    .flex-grid();

    @media @breakpoint-smartphone {
      .block();
    }

    .common-blog-post{
      width:calc(~"50% - "@common-blog-post-gutter);
      margin-right:@common-blog-post-gutter;
      margin-bottom:@common-blog-post-gutter*2;

      &:first-child{
        width:100%;
      }
    }

  }

  .common-blog-post{
    position:relative;
    cursor: pointer;

    article{
      .lh-display(flex);
      .lh-flex-direction(column);
    }

    .common-blog-more{
      line-height:40px;
      width:40px;
      height:40px;
      text-align: center;
      display:block;
      background:none;
      float:right;
    }

    @media @breakpoint-smartphone{
      .block();
      margin-bottom:@common-blog-post-gutter;
    }
  }

  .info-tags{
    ul{
      .clearlist;
      overflow:hidden;
      li{
        float:left;
      }
    }
  }

  .common-blog-article-content{

    .addresses{

      ul{
        .clearlist;
        li{
          overflow:hidden;

          figure{
            float:left;
            width:100px;

            img{
              width:100%;
            }
          }

          .content{
            width:calc(~"100% - 100px");
            float:left;
            padding:0 20px;
          }
        }
      }
    }
  }

  .common-blog-aside{
    width:calc(~"40% - "@common-blog-post-gutter);
    margin-left:@common-blog-post-gutter;
    .lh-order(2);

    figure{
      display:none;
    }
  }
}










/*================ Social share buttons ================*/
.social-sharing() {
  //font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  .font-family-regular;
  float: left;
  margin-left: -5px;
  /*================ Large Buttons ================*/

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  a {
    display: inline-block;
    color: #fff;
    border-radius: 2px;
    margin: 0 5px 0px 0;
    height: 22px;
    line-height: 22px;
    text-decoration: none;
    font-weight: normal;
  }

  a:hover {
    color: #fff;
  }

  span {
    display: inline-block;
    vertical-align: top;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
  }

  .icon {
    padding: 0 5px 0 10px;
  }

  .icon:before {
    line-height: 22px;
  }

  &.is-large a {
    height: 44px;
    line-height: 44px;
  }

  &.is-large a span {
    height: 44px;
    line-height: 44px;
    font-size: 18px;
  }

  &.is-large a .icon {
    padding: 0 10px 0 18px;
  }

  &.is-large a .icon:before {
    line-height: 44px;
  }

  .share-title {
    font-weight: 900;
    font-size: 12px;
    padding-right: 10px;
  }

  .is-large .share-title {
    padding-right: 16px;
  }

  .share-facebook {
    background-color: #3b5998;
  }

  .share-facebook:hover {
    background-color: #2d4373;
  }

  .share-twitter {
    background-color: #00aced;
  }

  .share-twitter:hover {
    background-color: #0087ba;
  }

  .share-pinterest {
    background-color: #cb2027;
  }

  .share-pinterest:hover {
    background-color: #9f191f;
  }

  .share-fancy {
    background-color: #4999dc;
  }

  .share-fancy:hover {
    background-color: #2780cb;
  }

  .share-google {
    background-color: #dd4b39;
  }

  .share-google:hover {
    background-color: #c23321;
  }

  .share-reddit {
    background-color: #5f99cf;
  }

  .share-reddit:hover {
    background-color: #3a80c1;
  }

  .share-linkedin {
    background-color: #0077b5;
  }

  .share-linkedin:hover {
    background-color: #005582;
  }

  .share-skype {
    background-color: #00aff0;
  }

  .share-skype:hover {
    background-color: #008abd;
  }

  .share-count {
    position: relative;
    background-color: #fff;
    padding: 0 8px;
    margin-right: -2px;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    border-radius: 0 2px 2px 0;
    border: 1px solid #ececec;
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in;
    -moz-transition: opacity 0.2s ease-in;
    -o-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
  }

  .share-count.is-loaded {
    opacity: 1;
  }

  .share-count:before,
  .share-count:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid;
  }

  .share-count:before {
    left: -6px;
    border-right-color: #ececec;
  }

  .share-count:after {
    left: -5px;
    border-right-color: #fff;
  }

  a:hover .share-count {
    background-color: whitesmoke;
  }

  a:hover .share-count:after {
    border-right-color: whitesmoke;
  }

  .is-large .share-count {
    font-size: 18px;
    padding: 0 14px;
  }

  /*================ Clean Buttons ================*/
  .social-sharing.is-clean a {
    background-color: #fff;
    border: 1px solid #ececec;
    color: #333;
    height: 30px;
    line-height: 30px;
  }

  .social-sharing.is-clean a span {
    height: 30px;
    line-height: 30px;
    font-size: 13px;
  }

  .social-sharing.is-clean a:hover {
    background-color: #ececec;
  }

  .social-sharing.is-clean a:hover .share-count {
    background-color: #fff;
  }

  .social-sharing.is-clean a:hover .share-count:after {
    border-right-color: #fff;
  }

  .social-sharing.is-clean a .share-title {
    font-weight: normal;
  }

  .social-sharing.is-clean .share-count {
    top: -1px;
  }

  .social-sharing.is-clean .icon-facebook {
    color: #3b5998;
  }

  .social-sharing.is-clean .icon-twitter {
    color: #00aced;
  }

  .social-sharing.is-clean .icon-pinterest {
    color: #cb2027;
  }

  .social-sharing.is-clean .icon-fancy {
    color: #4999dc;
  }

  .social-sharing.is-clean .icon-google {
    color: #dd4b39;
  }

  .social-sharing.is-clean .icon-reddit {
    color: #5f99cf;
  }

  .social-sharing.is-clean .icon-linkedin {
    color: #0077b5;
  }

  .social-sharing.is-clean .icon-skype {
    color: #00aff0;
  }

  .social-sharing.is-clean [class*="icon-"] {
    color: #333 !important;
  }
  .social-sharing.is-clean a:hover {
    background-color: #333;
    color: #fff !important;
  }
  .social-sharing.is-clean a:hover [class*="icon-"] {
    color: #fff !important;
  }

}
