@import "common.less";



.breadcrumbs{
  .size(11);
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
  margin-bottom: @gutter*2;

  @media @breakpoint-smartphone{
    display: none;
  }

  i{
    color: #aaa;
    .size(8);
    width: 10px;

    &:first-child{
      .size(12);
      color: @color-brand;
    }
  }
  #breadcrumbs {
    .wrapper-content();
    padding-top: @gutter*2;
    padding-bottom: @gutter*2;
    overflow: hidden;
    height: auto;

    a{
      color: @color-brand;
    }

    span, a, i{
      line-height: 20px;
      float:left;
      padding: 0;
    }
  }

  .page-home &{
    display: none;
  }
}


#header{
  position:relative;
  overflow: hidden;
  background: #000000;

  > .wrapper-content {
    position: relative;
    min-height: 300px;
  }

  .icon{
    position: absolute;
    bottom: 40px;
    left: -60px;
    width:80px;
    height:80px;
    line-height: 80px;
    .size(80);
    color: #fff;
    z-index: 10;

    @media @breakpoint-smartphone{
      display: none;
    }
  }

  .title{
    position: absolute;
    bottom: 0;//-@gutter;
    left:20%;
    z-index:10;
    .size(20);
    text-transform: uppercase;
    color: @color-brand-secondary;
    .font-special();
    .font-regular();
    display: table;

    @media @breakpoint-smartphone {
      display: block;
      left: @gutter*2 !important;
    }

    > span{
      display: table-cell;
      vertical-align: bottom;

      @media @breakpoint-smartphone {
        display: block;
      }

      > span{
        display: inline-block;
        background: rgba(255,255,255,0.9);
        padding: @gutter/2;
        .size(20);
      }

      &:first-child > span{
        @media @breakpoint-smartphone {
          position: relative;
        }
      }

      &:nth-child(even) span{
        .size(50);
        line-height: 50px;
        margin-left: -1px;
      }
    }
  }

  .page-donate &{
    .title{
      color: @color-brand-donation;
    }
  }

  .page-home &{
    > .wrapper-content{
      min-height: 400px;
      height: 80vh;
    }

    .title{
      display: block;
      bottom:50%;
      right:0;
      left:50%;
      .lh-transform(translateY(50%));

      > span{
        display: block;
        margin-bottom:2px;
        vertical-align: bottom;
        > span {
          background-color: transparent;
          text-shadow: 0px 0px 8px #ffffff;
        }
      }
    }
  }

  .background{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: @color-brand;
    background-size:cover;
  }
}



#footer{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7f7f7+0,ffffff+100 */
  background: #f7f7f7; /* Old browsers */
  background: -moz-linear-gradient(top,  #f7f7f7 0%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #f7f7f7 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #f7f7f7 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

  //padding: @gutter*4 @gutterMargin;
  padding-top: @gutter*2;

  @media @breakpoint-tablet{
    padding: 0;
  }

  & > .wrapper-content {
    .flex-grid();
    .wrapper-content();

    @media @breakpoint-smartphone{
      padding: @gutter*2;
    }
  }

  .col-internal{
    width:70%;
    .flex-grid();

    @media @breakpoint-tablet{
      width:100%;
    }
  }

  .col-external{
    width:30%;
    padding-left: @gutter;

    @media @breakpoint-tablet{
      width:100%;
    }
  }

  .partners{
    ul{
      .clearlist;
      overflow: hidden;
      padding: @gutter/2 0;
      margin-right: -@gutter/2;

      li{
        width: calc(~"50% - "@gutter/2);
        margin-right: @gutter/2;
        margin-bottom: @gutter/2;
        float:left;
        border:1px solid #eee;

        @media @breakpoint-tablet{
          width: calc(~"33.33% - "@gutter/2);
        }

        strong{
          display:none;
        }

        a{
          img{
            display:block;
            width:100%;
          }
        }
      }
    }
  }

  .address{
    width: 50%;
    background: @color-white;
    padding: @gutter*2;
    color: @color-brand-secondary;
    position: relative;

    &:after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: rgba(0,0,0,0.05);
    }

    h6{
      .size(24);
      .font-special();
      .font-bold();
      text-transform: uppercase;
      margin-bottom: @gutter*2;

      i{
        margin-right: 5px;
      }
    }

    .phone{
      .size(36);

      i{
        font-size: 0.6em;
      }
    }

    @media @breakpoint-tablet{
      width:50%;
    }

    @media @breakpoint-smartphone{
      width:100%;
      text-align: center;
    }
  }

  .donate{
    width: 50%;
    background: @color-brand-donation;
    padding: @gutter*2;
    text-align: center;
    color:#fff;
    position: relative;
    .lh-animation(blinker 2s linear infinite);

    &:after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: rgba(0,0,0,0.05);
    }

    h6{
      .size(15);
      .font-special();
      .font-light();
      text-transform: uppercase;
      margin-bottom: @gutter*2;

      i{
        margin-right: 5px;
      }
    }

    p{
      .size(24);
      .font-special();
      .font-bold();
      text-transform: uppercase;

      span{
        display:block;
        .size(36);
        font-family: 'Oxygen', sans-serif;
        .font-regular();
      }
    }
    @media @breakpoint-tablet{
      width:50%;
    }

    @media @breakpoint-smartphone{
      width:100%;
      text-align: center;
    }
  }

  .links{
    width: 100%;
    padding: @gutter*2;
    .flex-grid();

    > div{
      width: 33.33%;
      padding: @gutter*2;

      @media @breakpoint-smartphone{
        width:100%;
      }

      h6{
        .size(18);
        .font-special();
        .font-bold();
        text-transform: uppercase;
        color: @color-brand;
        margin-bottom: 10px;
        margin-top: @gutter*2;
      }

      ul{
        .clearlist;
        li{
          a{
            display: block;
            color: #666;
            .size(14);
            line-height: 20px;
            cursor: pointer;
            .font-special();
          }

          &:hover{
            a{
              color: @color-brand-secondary;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .copyright{
    background:#fff;
    width:100%;
    border-top:4px solid @color-brand;
    margin-top: @gutter;

    > .wrapper-content{
      .wrapper-content();
      overflow: hidden;
      padding: @gutter 0;
      display: table;
      width: 100%;
      text-transform: uppercase;
      .font-special();
      .size(14);
    }

    .site{
      color:#999;
      display: table-cell;
      vertical-align: middle;
    }

    .urls{
      color:#999;
      display: table-cell;
      vertical-align: middle;
    }

    .maven{
      display: table-cell;
      vertical-align: middle;
      text-align: right;
      .size(10);

      span{
        display: inline-block;
        line-height: 20px;
        margin-right: 5px;
      }

      a{
        color: #999;
      }

      svg{
        height: 12px;
        top: 2px;
        position: relative;
      }
      svg path{
        fill: @color-black;
      }
    }
  }
}


.content{
  h1, h2, h3{
    .style-title();
    margin-top: @gutter*4;
    &:first-child{
      margin-top: 0;
    }
  }

  h1 ~ h2,
  h1 ~ h3{
    margin-bottom: @gutter;

    &:after{
      display: none;
    }
  }

  h3{
    .size(18);
    margin-bottom: @gutter;
    color: @color-brand-secondary;
    &:after{
      display:none;
    }
  }

  .address, .ccp{
    padding: @gutter*2;
    border: 1px solid @color-brand;
    margin-bottom: @gutter*2;
    color: @color-brand;
  }

  .ccp{
    border-color: @color-brand-donation;
    color: @color-brand-donation;
    .size(22);
    strong{
      .size(14);
    }
  }

  ul{
    .size(15);
    max-width:600px;
    margin-bottom: @gutter*3;
    li{
      margin-bottom: @gutter;
      line-height: 1.5em;
    }
  }

  iframe{
    width: 100%;
    border: 10px solid #fff;
    margin: @gutter*2 auto !important;
    display: block;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.1);
  }

  form iframe{
    border: 0;
    margin: 0;
    box-shadow: none;
  }

  p{
    max-width:600px;
    .size(15);

    img{
      border: 10px solid #fff;
      margin: @gutter*2 auto !important;
      display: block;
      box-shadow: 0px 0px 3px rgba(0,0,0,0.1);

      &.none{
        box-shadow: none;
        border:0;
        background: none;
      }
    }

    &.headline,
    strong{
      .font-bold();
    }

    a{
      color: @color-brand;
      text-decoration: underline;
    }

    img{
      max-width: 90%;
      margin:auto;
    }
  }
}



.block.know{
  background: #fafafa;
  > .wrapper-content{
    > div{
      padding: @gutter*3 0;
    }
  }
  h2{
    color: @color-brand-donation;
    &:after{
      display: none;
    }
  }
}

.main-content{
  //border:1px solid red;
  //.flex-grid;
  //.wrapper-content();
  .clearfix;

  > .wrapper{
    .wrapper-content();
    .clearfix;

    @media @breakpoint-smartphone{
      .flex-grid;
      .lh-flex-direction(column);
    }
  }

  section,
  aside{
    //border:1px solid blue;
    margin-bottom: @gutter*3;

    @media @breakpoint-smartphone {
      padding: @gutter*2;
      margin: 0;
      float: none !important;
      width: 100% !important;
      display: block;
    }
  }

  aside{
    float: right;
    width: calc(~"45% - "@gutter*3);
    margin-left: @gutter*3;

    @media @breakpoint-smartphone{
      .block;
      .lh-order(20);
      padding: 0;
      margin: 0;
    }

    & ~ section{
      clear: left;
      width: 55%;

      @media @breakpoint-smartphone{
        .block;
        .lh-order(1);
      }
    }
  }


  .introduction{
    &:extend(.content all);
    width: 55%;
    float: left;
    //border: 1px solid red;
  }

  .block,
  #section-actions,
  #section-sponsors{
    width: 100%;
    float: none;
  }
  #section-sponsors{
    > .wrapper-content{
      padding-top: @gutter*3;
      padding-bottom: @gutter*3;
      position: relative;

      @media @breakpoint-smartphone{
        padding: 0 !important;
      }

      ul{
        li{
          margin-bottom: @gutter;

          a{
            border: 1px solid #eee;
          }
        }
      }
    }
    .view-all{
      .button();
      position:absolute;
      right: @gutter*4;
      top: @gutter*3;
      color: @color-brand-donation;
      border-color: @color-brand-donation;

      &:hover{
        background: @color-brand-donation;
      }
    }
  }
}

.block{
  &.stretch > .wrapper-content{
    .lh-flex-wrap(nowrap) !important;
  }
  > .wrapper-content{
    .wrapper-content;
    .flex-grid();
    padding: 0 @gutter*4;

    @media @breakpoint-smartphone {
      padding: 0;
    }

    > div{
      //padding: @gutter*4 @gutterMargin;
      padding-right: @gutter*3;

      &.content{
        width: 60%;
      }
      &.content + div{
        width: 40%;
      }

      img{
        max-width: 100%;
      }

      &.w-auto{

      }
      &.w-main{
        width: @width-main;
      }
      &.w-side{
        width: @width-side;
      }
      &.w-33{
        width: 33.33%;
      }
      &.w-25{
        width: 25%;
      }
      &.w-20{
        width: 20%;
      }

      &:last-child{
        padding-right: 0;
        padding-left: @gutter*3;
      }

      @media @breakpoint-smartphone {
        display: block;
        float: none;
        width: auto !important;
        padding: 0 !important;
        margin: 0 !important;
        margin-bottom: @gutter*2 !important;
      }

    }
  }

}

section.actions{
  > .wrapper-content{
    .wrapper-content();
    padding: 0 @gutterMargin;

    @media @breakpoint-smartphone{
      padding: 0;
    }

    .page-home &{
      height: 120px;
      @media @breakpoint-smartphone{
        height: auto;
      }
    }

    .page-actions &{
      padding: @gutter;
    }
  }
  ul{
    .clearlist;
    .flex-grid;
    position:relative;
    z-index:100;

    .page-home &{
      .lh-transform(translateY(-100px));
      @media @breakpoint-smartphone{
        .lh-transform(translateY(0));
      }
    }

    li{
      width: calc(~"25% - 2px");
      background: none no-repeat center;
      background-image: url('../images/bg-scratch.png');
      background-size: 90% auto;
      margin-right: 2px;
      padding: @gutter*2;
      padding-bottom: @gutter*3;
      position: relative;
      cursor: pointer;
      color: #fff;
      .animate();

      @media @breakpoint-smartphone{
        padding: @gutter;
      }

      .image{
        .size(60);
        display: block;
        margin: auto;
        text-align: center;
        line-height: 80px;

        @media @breakpoint-smartphone{
          position: absolute;
          top: @gutter;
          left: @gutter;
          .size(40);
          line-height: 40px;
        }
      }

      .page-home & {
        color: #fff !important;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 4px;
          background: rgba(0, 0, 0, 0.2);
        }
      }

      .page-actions &,
      .page-donate & {
        background-color: transparent !important;

        &:after {
          content: "";
          position: absolute;
          right: 0;
          height: 50%;
          width:1px;
          top:50%;
          .lh-transform(translateY(-50%;));
          background: @color-brand;
          opacity:0.3;
        }
        &:last-child:after{
          display:none;
        }
      }

      &:last-child{
        margin-right:0;
        width: calc(~"25%");
      }

      @media @breakpoint-smartphone{
        width: 100% !important;
        margin: 0;

        &:hover,
        &:after{
          .lh-transform(none) !important;
        }
        .arrow{
          display: none;
        }
      }

      .page-home &{
        .arrow{
          position: absolute;
          width: 1px;
          height:1px;
          top: 0;
          left: 50%;
          border: 15px solid transparent;
          opacity: 0;
          margin-left:-15px;
          .animate;
          display: none;
        }

        &:hover{
          .lh-transform(translateY(-20px));

          .arrow{
            opacity: 1;
            .lh-transform(translateY(-30px));
          }
        }
      }


      .page-actions &:hover {
        .lh-transform(scale(1.1));
      }



      img{
        width: 70%;
        display: block;
        margin:auto;
        opacity: 0.6;
      }

      .more{
        position: absolute;
        bottom: 0;
        right: 0;
        .size(20);
        margin: @gutter*2;
      }

      .title{
        .size(20);
        .font-special();
        .font-bold();
        text-transform: uppercase;
        text-align: center;

        @media @breakpoint-smartphone{
          text-align: left;
          padding-left: @gutter*5;
        }

        span:last-child{
          display: block;
          .font-regular();
          font-size: 0.8em;
          opacity:0.6;
        }
      }
    }
  }
}


.donation-sponsor{
  border-top:1px solid #eee;
  > .wrapper-content{
    .wrapper-content();
  }

  h2{
    .style-title();
    color: @color-brand-donation;
    &:after{
      background: @color-brand-donation;
    }
  }

  ul {
    .clearlist;
    margin-right: -@gutter;
    overflow:hidden;

    li {
      float: left;
      width: calc(~"16.66% - "@gutter);
      margin-right: @gutter;

      @media @breakpoint-smartphone{
        margin-right: 0;
        width: 50%;

        a{
          border: 0 !important;
        }
      }

      a{
        display: block;
      }
      img{
        width: 100%;
        display: block;
      }
    }
  }
}

.news-homepage{
  background: #fafafa;
  padding: @gutter;
  position:relative;

  .view-all{
    .button();
    position:absolute;
    right: @gutter*2;
    top: @gutter*2;
  }

  > h2{
    .style-subtitle();
    margin: @gutter;
    margin-bottom: @gutter*4;
  }

  ul{
    .clearlist;

    li{
      display: block;
      margin-bottom: @gutter !important;

      &:last-child{
        margin-bottom: 0 !important;
      }

      article{
        position:relative;
        min-height: calc(~"100px + "@gutter*2);
        padding: @gutter;

        &:hover{
          background: #fff;
        }

        header{
          padding-left: 120px;
        }

        .more{
          position: absolute;
          bottom:0;
          right:0;
          margin: @gutter;
          color: @color-brand;
          cursor: pointer;
        }

        time{
          position: absolute;
          top: 50%;
          left:@gutter;
          width:100px;
          text-align: center;
          line-height:35px;
          transform: translateY(-50%);

          span{
            display:block;
            color:#fff;
            font-size: 30px;
            line-height: 30px;
            .font-bold();
          }
        }

        .title{
          text-transform: uppercase;
          color: @color-brand-secondary;
          .font-special();
          .font-bold();
        }

        p{
          .size(13);
        }

        figure{
          position: absolute;
          top:@gutter;
          left:@gutter;
          width:100px;

          img{
            display:block;
            width:100%;
          }
        }
      }
    }
  }
}


.slider-header{
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;

  div, ul, li{
    height: 100%;
  }

  ul {
    position: relative;
    margin: 0;
    padding: 0;
    li {
      background-size: cover;
      opacity: 0;
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      z-index: 20;
      //.lh-transition(all 250ms ease-in-out 100ms);

      &:first-child{
        opacity: 1;
        z-index: 10;
        @media @breakpoint-smartphone {
          background-position: center;
        }
      }
    }
  }
}
[rel*="action"] .slider-header ul li:first-child{
  opacity: 0;
}
.action_vacances #action_vacances{
  opacity: 1;
  .animate();
}
.action_fourniture #action_fourniture{
  opacity: 1;
  .animate();
}
.action_parrainage #action_parrainage{
  opacity: 1;
  .animate();
}
.action_contribution #action_contribution{
  opacity: 1;
  .animate();
}
.slider-homepage{
  margin-bottom: @gutter*4;
  padding: 0 @gutter;
  position:relative;

  .view-all{
    .button();
    position:absolute;
    right: @gutter*2;
    top: 0;
  }

  > h2{
    .style-subtitle();
    margin: 0 @gutter;
    margin-bottom: @gutter*4;
  }

  > div{
    position: relative;

    &:before,
    &:after{
      content: "";
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index: 10;
      background:fade(#dae0e3, 40%);

      .lh-transform(scale(1.05) rotate(-1deg) translateX(5px));
    }

    &:after{
      .lh-transform(scale(1.05) rotate(1deg) translateX(-5px));
    }

  }

  .lSAction > a{
    background: #fff;
    opacity: 1;
    display: none;
  }
  .lSAction > .lSPrev{
    left:-20px;
  }
  .lSAction > .lSNext{
    right:-20px;
  }

  .lSPager{
    position: absolute;
    bottom:20px;
    left:50%;
    z-index: 100;
    .lh-transform(translateX(-50%));
  }

  .lSSlideOuter{
    background:#fff;
    padding:10px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
    position:relative;
    z-index: 20;
  }

  ul{
    .clearlist;

    li{
      article{
        position: relative;
        img{
          display:block;
        }
        header{
          position:absolute;
          bottom:40px;
          left:0;
          color:#fff;
          padding: @gutterDouble;

          time{
            background: fade(@color-brand, 80%);
            display:inline-block;
            padding: 4px 8px;
            .size(11);
          }
          p{
            display:none;
          }
          .title{
            display:block;
            margin-top:1px;
            .size(26);
            .font-special();

            a{
              display:inline-block;
              background: fade(@color-brand, 80%);
              padding: 4px 8px;
            }
          }
        }
      }
    }
  }
}

.page-home{
  .block{
    > .wrapper-content{
      > div{
        width:50%;
      }
    }
  }

  iframe{
    width: 100%;
    border: 10px solid #fff;
    margin: @gutter*2 auto !important;
    display: block;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.1);

    @media @breakpoint-smartphone {
      //display: none !important;
    }
  }
}

.page-interior{

  .about-comite{
    .clearlist;
    .flex-grid();

    li{
      width: 50%;
      text-align: center;
      padding: @gutter*2 !important;

      figure{
        width: 100%;
        margin: auto;
        overflow: hidden;
        border-radius: 50%;
        border: 10px solid #eee;
        margin-bottom: @gutter*2;

        img{
          width: 100%;
          display: block;
        }
      }

      span{
        display: block;
        text-align: center;

        &.name{
          .font-special();
          .font-bold();
          .size(20);
        }
        &.role{
          .font-special();
          .size(16);
          color: @color-brand;
        }
      }
    }
  }

  .back{
    .size(13);
    .font-special();
    .font-bold();
    color: @color-brand;
    text-transform: uppercase;
    display: block;
    margin-bottom: @gutter*2;

    @media @breakpoint-smartphone {
      margin-top: @gutter*2;
    }

    i{
      .size(9);
    }

    & + h1,
    & + h2{
      margin-top:0;
    }
  }
  &.page-donate .back{
    color: @color-brand-donation;
  }

  .action-icon{
    background: none no-repeat top center;
    background-image: url('../images/bg-scratch.png');
    background-size: 100% 100%;
    height: 300px;
    position: relative;
    margin-bottom: @gutter*2;

    i{
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -50px;
      margin-top: -50px;
      .size(100);
      color: @color-brand-secondary;
    }
  }


  .certification-zewo{
    background: #fff;
    margin-bottom: 0;

    img{
      width: 60px;
      float: left;
      display: block;
      margin-right: @gutter*2;
    }

    a{
      display:block;
      overflow: hidden;
    }

    h3{
      float: left;
    }

    p{
      clear: both;
    }
  }

  aside > section{
    background: #f7f7f7;
    padding: @gutter*2;
    margin-bottom: @gutter*2;

    h3{
      .style-title();
      .size(18);
    }

    p, li{
      .size(13);
    }

    .local-business{
      .contact{
        margin-top: @gutter*2;
        .phone, .fax{
          .size(20);
        }
      }
    }

    .ask-for-help,
    .ask-for-donation,
    .contact-us{
      background: @color-brand;
      margin: -@gutter*2;
      margin-top: @gutter*2;
      padding: @gutter*2;
      padding-left: 80px;
      padding-right: 60px;
      position: relative;
      cursor: pointer;
      display: block;
      color: @color-white;

      &.ask-for-donation{
        background: @color-brand-donation;
      }

      p a {
        color: @color-white;
      }

      i.help{
        .size(40);
        position: absolute;
        left:20px;
        top: 20px;
        color: @color-white;
      }

      i.arrow{
        .size(20);
        position: absolute;
        right:20px;
        top: 50%;
        margin-top: -10px;
        color: @color-white;
      }

      h3{
        color: @color-white;
        margin: 0;
        &:after{
          display: none;
        }
      }
    }
  }

  .block:nth-child(1){
    > div > div:nth-child(1){
      width: @width-main;
    }
    > div > div:nth-child(2){
      width: @width-side;
    }
  }
}

&.page-donate aside > section{
  background: lighten(@color-brand-donation, 45%);
  background: #f7f7f7;
  h3:after{
    background: @color-brand-donation;
  }
}

&.page-donation{
  strong{
    color: @color-brand-donation;
  }
}

.donation-persons{
  .clearlist;
  overflow: hidden;
  li{
    width: 50%;
    float: left;
    position: relative;
    padding-left: 50px !important;

    @media @breakpoint-smartphone {
      width: 100%;
      float: none;
      margin-bottom: @gutter !important;
      display: block;
    }

    figure{
      width: 40px;
      height: 40px;
      position: absolute;
      left:0;
      top:0;
      border-radius: 50%;
      background: #f7f7f7;
      line-height: 40px;
      text-align: center;
    }

    .name{
      .font-special();
      .font-regular();
      font-weight: 500;
      color: @color-brand-secondary;
      .size(16);
      display: block;
    }
    .region{
      .font-special();
      display: block;
      .size(14);
      margin-top: -2px;
    }
  }
}

.donation-companies{
  .clearlist;
  overflow: hidden;
  li{
    width: 33.33%;
    float: left;
    position: relative;

    @media @breakpoint-smartphone {
      width: 50%;
    }

    a{
      display: block;
    }

    img{
      width: 100%;
      display: block;
    }
  }
}

.donation-form,
.contact-form{

  p + &{
    margin-top: @gutter*4;
  }

  .paypal{
    width: 120px;
    height: 40px;
    float: left;
    padding-top:8px;

    img{
      width: 100%;
    }
  }

  > .donation-form-online,
  > .donation-form-virement,
  > .contact-form-online{
    padding: @gutter*4;
    position: relative;
    overflow: hidden;

    @media @breakpoint-smartphone{
      padding: @gutter*2;
    }

    .area{
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      height: 70px;
      z-index:10;
      cursor: pointer;
    }

    &:hover{
      background: #e7f8ff;
    }

    .icon {
      position: absolute;
      top: @gutter*2;
      right: @gutter*2;
    }
    .rdo{
      position: absolute;
      top: @gutter*2;
      left: @gutter*2;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background: #fff;
      border: 1px solid #333;

      &:after{
        position: absolute;
        top: 0;
        left: 0;
        background: @color-brand-donation;
        width: 14px;
        height: 14px;
        margin: 4px;
        border-radius: 50%;
        content: "";
        display: none;
      }
    }

    .form{
      display: none;
    }

    &.active{
      background: #e7f8ff;

      .rdo:after{
        display: block;
      }

      .icon{
        .lh-transform(rotate(90deg));
      }

      .form{
        display: block;
      }
    }

    h2{
      margin-top: 0;
    }
    h3{
      margin: 0;
      padding: 0;
     &:after{
       display:none;
     }
   }
    p{
      position: relative;
      .font-special();
      .font-light();
      .size(16);
      line-height: 1.2em;
      padding-bottom: @gutter;
      margin-top: @gutter;
      margin-bottom: @gutter*2;

      &:after{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        border-radius: 4px;
        width: 20px;
        content: "";
        background: @color-brand-donation;
      }
    }
  }
}

.msg{
  padding: @gutter*2;
  margin-bottom: @gutter*2;
  position: relative;

  .close{
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: block;
    margin: @gutter;
    z-index: 10;
    cursor: pointer;

    &:hover{
      background: #fff;
    }
  }

  strong{
    .style-title();
    color: @color-brand-donation;
    display: block;

    &:after{
      display: none;
    }
    margin-bottom: @gutter !important;
  }

  &.success{
    background: lighten( @color-success, 45%);
    strong{
      color: @color-success;
    }
  }

  &.error{
    background: lighten( @color-danger, 45%);
  }

}

.form{
  overflow: hidden;

  .g-recaptcha{
    margin-top: @gutter;
    padding-left: 100px;

    @media @breakpoint-smartphone{
      padding: 0;
    }
  }

  .field{
    clear: both;
    display: table;
    width:100%;
    margin-top: @gutter;

    > label{
      display: table-cell;
      line-height: 30px;
      .size(14);
      white-space: nowrap;
      width: 100px;
      padding-right: 10px;
      vertical-align: top;
      padding-top: 8px;
      text-align: right;

      @media @breakpoint-tablet{
        display: block;
        width: 100%;
        text-align: left;
      }

      @media @breakpoint-smartphone{
        display: block;
        width: 100%;
        text-align: left;
      }
    }

    .input{
      display: table-cell;
      padding: @gutter/2;
      background: #ffffff;

      @media @breakpoint-tablet{
        display: block;
        width: 100%;
      }

      @media @breakpoint-smartphone{
        display: block;
        width: 100%;
      }

      input, textarea, select{
        border: 0;
        background: none;
        line-height: 30px;
        .size(18);
        width: 100%;
        .font-special();
        .font-regular();
      }
    }

    &.textarea{
      .input{

      }
    }

    &.radio{
      padding-top: @gutter*2;

      > .input{
        background: none;
      }

      > label{
        display: block;
      }
      .input{
        display: block;
        overflow: hidden;
        border: 0;
        padding: @gutter;
        padding-bottom: 0;

        input[type="radio"],
        input[type="checkbox"]{
          visibility: hidden;
          position:absolute;
          top:0;
          left:0;
        }


        span{
          display:block;
          padding-left: 30px;
          .size(12);

          i{
            .font-bold();
            font-style: normal;
            .size(18);
          }

          &:after{
            content: "";
            position: absolute;
            top: 10px;
            left: 10px;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            background: #fff;
            border: 1px solid #333;
          }
          &:before{
            position: absolute;
            top: 10px;
            left: 10px;
            background: @color-brand-donation;
            width: 14px;
            height: 14px;
            margin: 4px;
            border-radius: 50%;
            content: "";
            display: none;
            z-index: 10;
          }
        }

        input:checked + span:before{
          display: block;
        }

        label{
          float: left;
          width: 33.33%;
          position:relative;
          padding: 10px;

          @media @breakpoint-tablet {
            float: none;
            width: 100%;
            padding: 0;
            margin: 0;

            span{
              line-height: 40px;
              padding-left: 40px;
            }
          }

          @media @breakpoint-smartphone {
            float: none;
            width: 100%;
            padding: 0;
            margin: 0;

            span{
              line-height: 40px;
              padding-left: 40px;
            }
          }

          &:hover{
            background: rgba(255,255,255,0.5);
          }
        }
      }
    }
  }

  footer{
    padding-top: @gutter*2;
    display: block;
    clear: both;
    text-align: right;
  }

  .btn{
    background: @color-brand-donation;
    color: #fff;
    .font-special();
    .font-regular();
    text-align: center;
    line-height: 34px;
    padding: 0 @gutter*2;
    border-radius: 4px;
    border: 0;
    .size(18);
    text-transform: uppercase;
    .animate();
    cursor: pointer;

    @media @breakpoint-smartphone {
      display: block;
      width: 100%;
    }

    &:hover{
      background: darken(@color-brand-donation, 10%);
    }
  }
}

.form{
  .field[rel='city']{
    width: 62%;
    float: right;
    clear: none;
  }
  .field[rel='zipcode']{
    width: 36%;
    float: left;
  }
}


.media-campaigns{
  .clearlist;
  margin-right: -@gutter*2 !important;

  li{
    float: left;
    width: calc(~"33.33% - "@gutter*2);
    position: relative;
    margin-right: @gutter*2 !important;
    margin-bottom: @gutter*2 !important;

    img{
      display: block;
      &:extend(.content p img);
    }

    h3{
      margin-bottom: 0 !important;
    }

    &.horizontal{
      width: calc(~"100% - "@gutter*2);
    }
  }
}




.page-blog-article{
  #header > .wrapper-content{
    min-height: 200px;
  }
  #header > .wrapper-content h1{
    display: none;
  }
  @media @breakpoint-smartphone {
    padding: @gutter*2 !important;
    #header{
      display: none;
    }
  }
}

.common-blog-aside{
}

.common-blog-aside-categories,
.common-blog-aside-tags{
  &:extend(.page-interior aside);
  float: none !important;
  width: auto !important;
  margin-bottom: @gutter*2;
  background: #f9f9f9 !important;
  overflow:hidden;
  padding: @gutter*2;

  @media @breakpoint-smartphone{
    padding: @gutter*2 !important;
  }

  .title{
    .style-title();
    .size(18);
  }

  p, li{
    .size(13);
  }

  ul{
    .clearlist;
    &:extend(.subnavigation ul all);
    li{
      &.active{
        a{
          background: @color-brand;
          color: #fff;
        }
      }
    }
  }
  .tags{
    overflow: hidden;
    a{
      &:extend(.subnavigation ul li a);
      float: left;
      margin-right: 4px;
      margin-bottom: 4px;
      background: none;
      border-color: #aaa;
      color: #aaa;
      padding: 0 10px;
      line-height: 20px;
      .size(12);
    }
  }
}

.common-blog-galleries{
  > div{
    margin-bottom: @gutter*3;
    ul {
      .clearlist;
    }
  }
  .gallery-image{
    ul{
      overflow: hidden;
      margin: 0;
      padding-top: 10px;
      padding-left: 10px;
      margin-bottom: 0 !important;
      background: #f7f7f7;

      li{
        float: left;
        width: calc(~"25% - 10px");
        margin-right: 10px !important;
        margin-bottom: 10px !important;

        @media @breakpoint-smartphone {
          float: left;
          width: calc(~"50% - 10px");
        }

        span{
          display: none;
        }

        a{
          display: block;
          &:extend(.content p img);
          margin: 0 !important;
          max-width: 100%;

          img{
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
}

.common-blog-article{
  .flex-grid();
  .lh-flex-direction(column);
  position: relative;

  .common-blog-content{
    .lh-order(2);
    border-bottom: 1px solid #eee;
    padding-bottom: @gutter*2;
    margin-bottom: @gutter*2;

    h2,h3,h4,h1{
      text-transform: none;
      margin: @gutter*2 0 @gutter/2 0;
      font-weight: 600;
      &:after{
        display: none;
      }
    }
  }

  .common-blog-galleries{
    .lh-order(3);
  }

  .categories{
    .lh-order(4);
    ul{
      .clearlist;
      li a{
        display: inline-block;
        list-style: none;
        text-transform: uppercase;
        padding: 2px 6px;
        margin-right: 2px !important;
        margin-bottom: 2px !important;
        font-size: 11px;
        border-radius: 3px;
        background: #eee;
      }
    }
  }

  .tags{
    .lh-order(5);
    margin-top: @gutter*2;
    ul{
      .clearlist;
      li a{
        display: inline-block;
        list-style: none;
        text-transform: uppercase;
        padding: 2px 6px;
        margin-right: 2px !important;
        margin-bottom: 2px !important;
        font-size: 11px;
        border-radius: 3px;
        background: #eee;
      }
    }
  }

  .pagination-navigation{
    position: absolute;
    top: @gutter*5;
    right:0;
    display: none;

    .btn-previous,
    .btn-next{
      float: left;
      width: 40px;
      height: 40px;
      background: #f7f7f7;
      margin-left: @gutter;
      text-align: center;
      line-height: 36px;
      opacity: 0.2;
      border-radius: 3px;
      cursor:pointer;
      span{
        display: none;
      }

      &.btn-next{
        float: right;
      }
    }
    a{
      opacity: 1 !important;
      &:hover{
        background: @color-brand;
        color: #fff;
      }
    }
  }

  .share{
    .lh-order(5);
    padding: 0;
    border-top: 1px solid #eee;
    padding-top: @gutter*2;
    margin-top: @gutter*2;
    margin-bottom: @gutter*2;
    background: none;
    overflow: hidden;

    .social-sharing{
      float: right;
    }
  }

  header{
    .lh-order(1);
    .flex-grid();
    .lh-flex-direction(column);

    figure{
      .lh-order(3);
      margin-bottom: @gutter*2;
      img{
        &:extend(.content p img);
        margin: 0!important;
      }
    }
    .date{
      .lh-order(2);
      font-size: 0.8em;
      color: #aaa;
      margin-bottom: @gutter*2;
      time, a{
        color: @color-black;
      }
      .author:before{
        content: "|";
        display: inline-block;
        margin: 0 4px;
      }
    }
    .title{
      .lh-order(1);
      margin: 0;
      margin-bottom: @gutter*2;
      text-transform: none;
      font-weight: 600;
      .size(50);
    }
  }
}

.common-blog{

  > .wrapper-content{
    .wrapper-content();

    @media @breakpoint-smartphone{
      padding: 0;
    }
  }

  .common-blog-listing{
    .clearlist;
    .flex-grid;
    margin-right: -@gutter*2;

    > li{
      width: 100%;
      border-bottom: 1px solid @color-brand;

      &:last-child{
        border: 0;
      }

      article{
        padding-left: 140px;

        @media @breakpoint-smartphone{
          padding-left: 120px;
          padding-right: 20px;
        }

        header{
          .title{
            .size(22);
          }
          figure {
            position: absolute;
            top: @gutter*2;
            left: 0;
            height: 100px;
            width: 100px;
            background: #f7f7f7;
          }
        }
      }

      &.highlight{
        width: 100%;

        article{
          padding-left: 0;

          header{
            .title{
              .size(30);
            }
            figure {
              position: relative;
              top: 0;
              left: 0;
              height: auto;
              width: 100%;
              background: #f7f7f7;
            }
          }
        }
      }
    }

    article{
      //border-bottom: 2px solid @color-brand;
      position: relative;
      padding: @gutter*2 0 @gutter 0;


      .categories{
        display: none;
      }

      .common-blog-galleries{
        ul{
          .clearlist;
        }
      }

      header{
        .flex-grid();
        .lh-flex-direction(column);

        figure{
          .lh-order(3);
          margin-bottom: @gutter*2;

          img{
            &:extend(.content p img);
            margin: 0 !important;
            max-width: 100%;
            width: 100%;
          }
        }
        .date{
          .lh-order(2);
          font-size: 0.8em;
          color: #aaa;
          margin-bottom: @gutter;
          time, a{
            color: @color-black;
          }
          .author:before{
            content: "|";
            display: inline-block;
            margin: 0 4px;
          }
        }
        .title{
          .lh-order(1);
          margin: 0;
          margin-bottom: @gutter*2;
          text-transform: none;
          font-weight: 600;
          .size(30);
        }
      }

      .common-blog-content,
      .common-blog-resume{
        overflow: hidden;
        position: relative;

        p:last-child{
          margin-top: @gutter*2;
        }
      }

      .common-blog-more{
        float: right;

        @media @breakpoint-smartphone{
          float: none;
        }

        i{
          display: none;
        }
        a{
          .size(13);
          color: @color-brand;
          text-decoration: none;
        }
      }
    }
  }
}


.social-sharing{
  .social-sharing();
}


@keyframes blinker {
  0%{ background: @color-brand-donation; }
  50% { background: darken(@color-brand-donation, 10%); }
  100%{ background: @color-brand-donation; }
}