html,
body,
form,
figure,
article,
section,
nav,
video,
audio,
header,
footer,
fieldset,
h1, h2, h3, h4, h5, h6,
dl, dt, dd, p{
  margin:0;
  padding:0;
  list-style: none;
  word-wrap: break-word;
}

header,
nav,
main,
footer,
article,
section,
aside,
figure {
  display:block;
}

input,
textarea,
select{
  font-size:100%;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill{
  background:#fff !important;
}

*:focus{
  outline:0;
}

html,body{
  padding:0;
  margin:0;
  height:100%;
  width:100%;
  background:#fff;
}

html{
  font-size:@font-size;

  @media @mq-s{
    font-size:14px;
  }

  @media @mq-xs{
    font-size:14px;
  }

  body{
    overflow-y:auto;
    overflow-x:hidden;
  }
}

*{
  box-sizing:border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
}

#skip-link{
  a{
    position:absolute;
    top:-100px;
    left:0px;
    overflow:hidden;
    width:1px;
    height:1px;
  }
}

a{
  text-decoration:none;
  color:inherit;

  &:hover,
  &:visited,
  &:active{
    text-decoration:none;
  }
  &:focus{
    outline:0;
  }
}

p{
  line-height:1.5em;
  margin-bottom:@gutter;
  a{
    display:inline-block;
  }
}

#wrapper{
  max-width:@wrapper-max-width;
  .lh-display(flex);
  .lh-flex-direction(column);
}

.logo(){
  strong{
    position:absolute;
    text-indent: -9999px;
    width:1px;
    height: 1px;
    overflow: hidden;
  }
  a{
    text-decoration: none;
    display:block;
  }
  img{
    width:100%;
  }
}

nav{
  ul{
    .clearlist();

    li{
      display:inline-block;

      a{
        display:block;
      }
    }
  }
}


h1, h2, h3, h4, h5, h6{
  line-height: 1.2em;
}