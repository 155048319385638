@import (reference) "node_modules/lesshat/build/lesshat-prefixed.less";
@import "mediaqueries.less";
@import "mixins.less";
@import "reset.less";
@import "../../fonts/icons/icons.less";
@import "variables.less";
@import "navigation.less";



// BREAKPOINTS - RESPONSIVE
@breakpoint-smartphone: @mq-xs;
@breakpoint-tablet: @mq-s;
@breakpoint-tablet-horizontal: @mq-tablet-horizontal;
//

@font-size: 16px;
@wrapper-max-width:100%;//unit((1000 / @font-size), rem);
@wrapper-content-max-width:unit((900 / @font-size), rem);

html{
  font-family: 'Oxygen', sans-serif;
  .font-family-regular();
  font-weight: 100;
  font-size: @font-size;
  min-height: 100%;
}
body{
  min-height: 100%;
  background:#ffffff;
  color: @color-black;
  //.parallax;
}


.wrapper-content(){
  max-width:1100px;
  margin:auto !important;
  //border: 1px solid green;
  padding: 0 @gutterMargin;
}

#mainx{

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7f7f7+0,ffffff+100 */
  background: #eff7fa; /* Old browsers */
  background: -moz-linear-gradient(top,  #eff7fa 0%, #ffffff 50%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #eff7fa 0%,#ffffff 50%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #eff7fa 0%,#ffffff 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eff7fa', endColorstr='#ffffff',GradientType=0.5 ); /* IE6-9 */

}

#wrapper{
  //padding:0 @gutterDouble;
  > section{
    position: relative;
    .lh-transform(translateZ(0));
  }
}

.font-special(){
  font-family: 'Dosis', sans-serif;
  font-weight:300;
}

.font-light(){
  font-weight:300;
}
.font-regular(){
  font-weight: 400;
}
.font-bold(){
  font-weight: 700;
}



// COMPATIBILITE
.font-family-light(){
  .font-light();
}
.font-family-regular(){
  .font-regular();
}


.button(){
  background: #fff;
  border: 1px solid @color-brand;
  border-radius: 3px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 @gutter;
  .size(11);
  .font-special();
  font-weight: 700;
  display: inline-block;
  .animate();
  color: @color-brand;

  &:hover{
    background: @color-brand;
    color:#fff;
  }

  i{
    .size(11);
    margin-right:5px;
    position:relative;
    top:-2px;
  }

  .page-donate &{
    color: @color-brand-donation;
    border-color: @color-brand-donation;

    &:hover{
      background: @color-brand-donation;
      color: #fff;

      i{
        color: #fff;
      }
    }
  }
}


.style-title(){
  .size(24);
  color: @color-black;
  .font-special();
  .font-bold();
  position: relative;
  padding-bottom: @gutter;
  margin-bottom: @gutter*3;
  text-transform: uppercase;

  &:after{
    position: absolute;
    content: "";
    bottom: 0;
    left:0;
    height: 2px;
    width: @gutter*2;
    background: @color-brand-secondary;
    border-radius:4px;
  }

  span{
    display:block;
    .font-regular();
    font-size: 0.8em;
  }
}


.style-subtitle(){
  .size(18);
  color: @color-black;
  .font-special();
  .font-bold();
  position: relative;
  padding-bottom: @gutter;
  margin-bottom: @gutter*3;
  text-transform: uppercase;

  &:after{
    position: absolute;
    content: "";
    bottom: 0;
    left:0;
    height: 2px;
    width: @gutterDouble*2;
    background: @color-brand-secondary;
    border-radius:4px;
  }

  span{
    display:block;
    .font-regular();
    font-size: 0.8em;
    color: @color-brand;
  }
}


@media @breakpoint-smartphone{

}