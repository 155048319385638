// Generated by grunt-webfont


@font-face {
	font-family:"icons";
	src:url("../fonts/icons/icons.eot?f54c907be9f21d64a1593cbe60026ae3");
	src:url("../fonts/icons/icons.eot?#iefix") format("embedded-opentype"),
		url("../fonts/icons/icons.woff?f54c907be9f21d64a1593cbe60026ae3") format("woff"),
		url("../fonts/icons/icons.ttf?f54c907be9f21d64a1593cbe60026ae3") format("truetype");
	font-weight:normal;
	font-style:normal;
}

.icon {
	&:before {
		font-family:"icons";
	}
	display:inline-block;
	vertical-align:middle;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}


// Icons

.icon.icon-analysis {
	&:before {
		content:"\f101";
	}
}


.icon.icon-arrow-down {
	&:before {
		content:"\f102";
	}
}


.icon.icon-arrow-left {
	&:before {
		content:"\f103";
	}
}


.icon.icon-arrow-right {
	&:before {
		content:"\f104";
	}
}


.icon.icon-back {
	&:before {
		content:"\f105";
	}
}


.icon.icon-basket {
	&:before {
		content:"\f106";
	}
}


.icon.icon-blog {
	&:before {
		content:"\f107";
	}
}


.icon.icon-calendar {
	&:before {
		content:"\f108";
	}
}


.icon.icon-case {
	&:before {
		content:"\f109";
	}
}


.icon.icon-certificate {
	&:before {
		content:"\f10a";
	}
}


.icon.icon-clock {
	&:before {
		content:"\f10b";
	}
}


.icon.icon-close {
	&:before {
		content:"\f10c";
	}
}


.icon.icon-cog {
	&:before {
		content:"\f10d";
	}
}


.icon.icon-collaboration {
	&:before {
		content:"\f10e";
	}
}


.icon.icon-company {
	&:before {
		content:"\f10f";
	}
}


.icon.icon-consult {
	&:before {
		content:"\f110";
	}
}


.icon.icon-dashboard {
	&:before {
		content:"\f111";
	}
}


.icon.icon-download {
	&:before {
		content:"\f112";
	}
}


.icon.icon-earth {
	&:before {
		content:"\f113";
	}
}


.icon.icon-edit {
	&:before {
		content:"\f114";
	}
}


.icon.icon-facebook-plain {
	&:before {
		content:"\f115";
	}
}


.icon.icon-facebook {
	&:before {
		content:"\f116";
	}
}


.icon.icon-field-check-empty {
	&:before {
		content:"\f117";
	}
}


.icon.icon-field-check {
	&:before {
		content:"\f118";
	}
}


.icon.icon-folder-open {
	&:before {
		content:"\f119";
	}
}


.icon.icon-folder {
	&:before {
		content:"\f11a";
	}
}


.icon.icon-google {
	&:before {
		content:"\f11b";
	}
}


.icon.icon-group {
	&:before {
		content:"\f11c";
	}
}


.icon.icon-heart {
	&:before {
		content:"\f11d";
	}
}


.icon.icon-high {
	&:before {
		content:"\f11e";
	}
}


.icon.icon-home {
	&:before {
		content:"\f11f";
	}
}


.icon.icon-instagram {
	&:before {
		content:"\f120";
	}
}


.icon.icon-like {
	&:before {
		content:"\f121";
	}
}


.icon.icon-linkedin-plain {
	&:before {
		content:"\f122";
	}
}


.icon.icon-linkedin {
	&:before {
		content:"\f123";
	}
}


.icon.icon-loader {
	&:before {
		content:"\f124";
	}
}


.icon.icon-loading {
	&:before {
		content:"\f125";
	}
}


.icon.icon-logo {
	&:before {
		content:"\f126";
	}
}


.icon.icon-low {
	&:before {
		content:"\f127";
	}
}


.icon.icon-mail-plain {
	&:before {
		content:"\f128";
	}
}


.icon.icon-mail {
	&:before {
		content:"\f129";
	}
}


.icon.icon-map {
	&:before {
		content:"\f12a";
	}
}


.icon.icon-marker {
	&:before {
		content:"\f12b";
	}
}


.icon.icon-menu {
	&:before {
		content:"\f12c";
	}
}


.icon.icon-middle {
	&:before {
		content:"\f12d";
	}
}


.icon.icon-mobile {
	&:before {
		content:"\f12e";
	}
}


.icon.icon-module-banner {
	&:before {
		content:"\f12f";
	}
}


.icon.icon-module-blog {
	&:before {
		content:"\f130";
	}
}


.icon.icon-module-campaign {
	&:before {
		content:"\f131";
	}
}


.icon.icon-module-contact {
	&:before {
		content:"\f132";
	}
}


.icon.icon-module-content {
	&:before {
		content:"\f133";
	}
}


.icon.icon-module-core {
	&:before {
		content:"\f134";
	}
}


.icon.icon-money {
	&:before {
		content:"\f135";
	}
}


.icon.icon-more {
	&:before {
		content:"\f136";
	}
}


.icon.icon-network {
	&:before {
		content:"\f137";
	}
}


.icon.icon-no-results {
	&:before {
		content:"\f138";
	}
}


.icon.icon-picture {
	&:before {
		content:"\f139";
	}
}


.icon.icon-plus {
	&:before {
		content:"\f13a";
	}
}


.icon.icon-profile {
	&:before {
		content:"\f13b";
	}
}


.icon.icon-progress {
	&:before {
		content:"\f13c";
	}
}


.icon.icon-report {
	&:before {
		content:"\f13d";
	}
}


.icon.icon-request {
	&:before {
		content:"\f13e";
	}
}


.icon.icon-save {
	&:before {
		content:"\f13f";
	}
}


.icon.icon-search {
	&:before {
		content:"\f140";
	}
}


.icon.icon-segment {
	&:before {
		content:"\f141";
	}
}


.icon.icon-send {
	&:before {
		content:"\f142";
	}
}


.icon.icon-sended {
	&:before {
		content:"\f143";
	}
}


.icon.icon-shv-bulle {
	&:before {
		content:"\f144";
	}
}


.icon.icon-shv-coeur {
	&:before {
		content:"\f145";
	}
}


.icon.icon-shv-lit {
	&:before {
		content:"\f146";
	}
}


.icon.icon-shv-maison {
	&:before {
		content:"\f147";
	}
}


.icon.icon-shv-phone {
	&:before {
		content:"\f148";
	}
}


.icon.icon-shv-soleil {
	&:before {
		content:"\f149";
	}
}


.icon.icon-shv-valise {
	&:before {
		content:"\f14a";
	}
}


.icon.icon-sms {
	&:before {
		content:"\f14b";
	}
}


.icon.icon-tick {
	&:before {
		content:"\f14c";
	}
}


.icon.icon-translation {
	&:before {
		content:"\f14d";
	}
}


.icon.icon-twitter-plain {
	&:before {
		content:"\f14e";
	}
}


.icon.icon-twitter {
	&:before {
		content:"\f14f";
	}
}


.icon.icon-upload {
	&:before {
		content:"\f150";
	}
}


.icon.icon-user {
	&:before {
		content:"\f151";
	}
}


.icon.icon-users {
	&:before {
		content:"\f152";
	}
}


.icon.icon-view {
	&:before {
		content:"\f153";
	}
}


.icon.icon-wallet {
	&:before {
		content:"\f154";
	}
}


.icon.icon-youtube {
	&:before {
		content:"\f155";
	}
}

