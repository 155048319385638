/* MEDIA QUERIES */
/* MIXINS */
/* WEEBOX */
/*================ Social share buttons ================*/
html,
body,
form,
figure,
article,
section,
nav,
video,
audio,
header,
footer,
fieldset,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd,
p {
  margin: 0;
  padding: 0;
  list-style: none;
  word-wrap: break-word;
}
header,
nav,
main,
footer,
article,
section,
aside,
figure {
  display: block;
}
input,
textarea,
select {
  font-size: 100%;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #fff !important;
}
*:focus {
  outline: 0;
}
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background: #fff;
}
html {
  font-size: 16px;
}
@media only screen and (max-width: 991px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 640px) {
  html {
    font-size: 14px;
  }
}
html body {
  overflow-y: auto;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}
#skip-link a {
  position: absolute;
  top: -100px;
  left: 0px;
  overflow: hidden;
  width: 1px;
  height: 1px;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:visited,
a:active {
  text-decoration: none;
}
a:focus {
  outline: 0;
}
p {
  line-height: 1.5em;
  margin-bottom: 0.625rem;
}
p a {
  display: inline-block;
}
#wrapper {
  max-width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
nav ul li {
  padding: 0;
  margin: 0;
}
nav ul li {
  display: inline-block;
}
nav ul li a {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
}
@font-face {
  font-family: "icons";
  src: url("../fonts/icons/icons.eot?f54c907be9f21d64a1593cbe60026ae3");
  src: url("../fonts/icons/icons.eot?#iefix") format("embedded-opentype"), url("../fonts/icons/icons.woff?f54c907be9f21d64a1593cbe60026ae3") format("woff"), url("../fonts/icons/icons.ttf?f54c907be9f21d64a1593cbe60026ae3") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon:before {
  font-family: "icons";
}
.icon.icon-analysis:before {
  content: "\f101";
}
.icon.icon-arrow-down:before {
  content: "\f102";
}
.icon.icon-arrow-left:before {
  content: "\f103";
}
.icon.icon-arrow-right:before {
  content: "\f104";
}
.icon.icon-back:before {
  content: "\f105";
}
.icon.icon-basket:before {
  content: "\f106";
}
.icon.icon-blog:before {
  content: "\f107";
}
.icon.icon-calendar:before {
  content: "\f108";
}
.icon.icon-case:before {
  content: "\f109";
}
.icon.icon-certificate:before {
  content: "\f10a";
}
.icon.icon-clock:before {
  content: "\f10b";
}
.icon.icon-close:before {
  content: "\f10c";
}
.icon.icon-cog:before {
  content: "\f10d";
}
.icon.icon-collaboration:before {
  content: "\f10e";
}
.icon.icon-company:before {
  content: "\f10f";
}
.icon.icon-consult:before {
  content: "\f110";
}
.icon.icon-dashboard:before {
  content: "\f111";
}
.icon.icon-download:before {
  content: "\f112";
}
.icon.icon-earth:before {
  content: "\f113";
}
.icon.icon-edit:before {
  content: "\f114";
}
.icon.icon-facebook-plain:before {
  content: "\f115";
}
.icon.icon-facebook:before {
  content: "\f116";
}
.icon.icon-field-check-empty:before {
  content: "\f117";
}
.icon.icon-field-check:before {
  content: "\f118";
}
.icon.icon-folder-open:before {
  content: "\f119";
}
.icon.icon-folder:before {
  content: "\f11a";
}
.icon.icon-google:before {
  content: "\f11b";
}
.icon.icon-group:before {
  content: "\f11c";
}
.icon.icon-heart:before {
  content: "\f11d";
}
.icon.icon-high:before {
  content: "\f11e";
}
.icon.icon-home:before {
  content: "\f11f";
}
.icon.icon-instagram:before {
  content: "\f120";
}
.icon.icon-like:before {
  content: "\f121";
}
.icon.icon-linkedin-plain:before {
  content: "\f122";
}
.icon.icon-linkedin:before {
  content: "\f123";
}
.icon.icon-loader:before {
  content: "\f124";
}
.icon.icon-loading:before {
  content: "\f125";
}
.icon.icon-logo:before {
  content: "\f126";
}
.icon.icon-low:before {
  content: "\f127";
}
.icon.icon-mail-plain:before {
  content: "\f128";
}
.icon.icon-mail:before {
  content: "\f129";
}
.icon.icon-map:before {
  content: "\f12a";
}
.icon.icon-marker:before {
  content: "\f12b";
}
.icon.icon-menu:before {
  content: "\f12c";
}
.icon.icon-middle:before {
  content: "\f12d";
}
.icon.icon-mobile:before {
  content: "\f12e";
}
.icon.icon-module-banner:before {
  content: "\f12f";
}
.icon.icon-module-blog:before {
  content: "\f130";
}
.icon.icon-module-campaign:before {
  content: "\f131";
}
.icon.icon-module-contact:before {
  content: "\f132";
}
.icon.icon-module-content:before {
  content: "\f133";
}
.icon.icon-module-core:before {
  content: "\f134";
}
.icon.icon-money:before {
  content: "\f135";
}
.icon.icon-more:before {
  content: "\f136";
}
.icon.icon-network:before {
  content: "\f137";
}
.icon.icon-no-results:before {
  content: "\f138";
}
.icon.icon-picture:before {
  content: "\f139";
}
.icon.icon-plus:before {
  content: "\f13a";
}
.icon.icon-profile:before {
  content: "\f13b";
}
.icon.icon-progress:before {
  content: "\f13c";
}
.icon.icon-report:before {
  content: "\f13d";
}
.icon.icon-request:before {
  content: "\f13e";
}
.icon.icon-save:before {
  content: "\f13f";
}
.icon.icon-search:before {
  content: "\f140";
}
.icon.icon-segment:before {
  content: "\f141";
}
.icon.icon-send:before {
  content: "\f142";
}
.icon.icon-sended:before {
  content: "\f143";
}
.icon.icon-shv-bulle:before {
  content: "\f144";
}
.icon.icon-shv-coeur:before {
  content: "\f145";
}
.icon.icon-shv-lit:before {
  content: "\f146";
}
.icon.icon-shv-maison:before {
  content: "\f147";
}
.icon.icon-shv-phone:before {
  content: "\f148";
}
.icon.icon-shv-soleil:before {
  content: "\f149";
}
.icon.icon-shv-valise:before {
  content: "\f14a";
}
.icon.icon-sms:before {
  content: "\f14b";
}
.icon.icon-tick:before {
  content: "\f14c";
}
.icon.icon-translation:before {
  content: "\f14d";
}
.icon.icon-twitter-plain:before {
  content: "\f14e";
}
.icon.icon-twitter:before {
  content: "\f14f";
}
.icon.icon-upload:before {
  content: "\f150";
}
.icon.icon-user:before {
  content: "\f151";
}
.icon.icon-users:before {
  content: "\f152";
}
.icon.icon-view:before {
  content: "\f153";
}
.icon.icon-wallet:before {
  content: "\f154";
}
.icon.icon-youtube:before {
  content: "\f155";
}
/*
.size(@val:1) { 
    @return: unit(@val/@font-size, em); 
}
*/
/*@gutter:unit(20/@font-size, rem);*/
body #page {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
@media only screen and (max-width: 991px) {
  .mobile-navigation-opened body {
    overflow: hidden;
  }
  .mobile-navigation-opened body #page #wrapper,
  .mobile-navigation-opened body #page #navigation {
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    -webkit-transform: translateX(-250px);
    -moz-transform: translateX(-250px);
    -o-transform: translateX(-250px);
    -ms-transform: translateX(-250px);
    transform: translateX(-250px);
  }
}
#navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
@media only screen and (max-width: 991px) {
  .mobile-navigation-opened #navigation {
    bottom: 0;
  }
}
#navigation > .wrapper-content {
  max-width: 1100px;
  margin: auto !important;
  padding: 0 2.5rem;
  background: rgba(255, 255, 255, 0.95);
  border-top: 4px solid #009ed9;
  display: table;
  width: 100%;
  min-height: 100px;
}
@media only screen and (max-width: 991px) {
  #navigation > .wrapper-content {
    min-height: 60px;
    border: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    padding-left: 0.625rem;
  }
}
#navigation .mobile-navigation {
  position: absolute;
  right: 1.25rem;
  top: 30px;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  display: none;
}
#navigation .mobile-navigation span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  line-height: 40px;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
#navigation .mobile-navigation span.icon-close {
  opacity: 0;
}
#navigation .mobile-navigation span.icon-menu {
  font-size: 20px;
}
@media only screen and (max-width: 991px) {
  #navigation .mobile-navigation {
    display: block;
  }
}
.mobile-navigation-opened #navigation .mobile-navigation span.icon-close {
  opacity: 1;
}
.mobile-navigation-opened #navigation .mobile-navigation span.icon-menu {
  opacity: 0;
}
#navigation .navigation-container {
  display: table-cell;
  vertical-align: bottom;
  text-align: right;
}
@media only screen and (max-width: 991px) {
  #navigation .navigation-container {
    display: block;
    position: absolute;
    top: 0;
    left: 100%;
    width: 250px;
    bottom: 0;
    background: #009ed9;
    overflow-y: scroll;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }
}
#navigation .logo {
  width: 25%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  #navigation .logo {
    width: auto;
    display: block;
    margin-top: 0.625rem;
    max-width: 190px;
  }
}
#navigation .logo a {
  display: block;
  width: 90%;
  margin: auto;
}
#navigation .logo img {
  width: 100%;
  display: block;
}
#navigation .logo strong {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
#navigation .navigation.topbar ul li a,
#navigation .topbar.topbar ul li a {
  line-height: 50px;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 400;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #006188;
  padding: 0 0.625rem;
}
@media only screen and (max-width: 991px) {
  #navigation .navigation.topbar ul li.facebook,
  #navigation .topbar.topbar ul li.facebook {
    display: none !important;
  }
}
#navigation .navigation.navigation ul,
#navigation .topbar.navigation ul {
  display: table;
  float: right;
}
@media only screen and (max-width: 991px) {
  #navigation .navigation.navigation ul,
  #navigation .topbar.navigation ul {
    float: none;
  }
}
#navigation .navigation.navigation ul li,
#navigation .topbar.navigation ul li {
  display: table-cell;
  position: relative;
  text-align: center;
}
#navigation .navigation.navigation ul li a,
#navigation .topbar.navigation ul li a {
  line-height: 60px;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 400;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #009ed9;
  padding: 0 0.625rem;
}
#navigation .navigation.navigation ul li:hover,
#navigation .topbar.navigation ul li:hover {
  background: #fff;
}
#navigation .navigation.navigation ul li.active a,
#navigation .topbar.navigation ul li.active a {
  font-weight: 700;
  color: #006188;
}
#navigation .navigation.navigation ul li.active:after,
#navigation .topbar.navigation ul li.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: #006188;
}
#navigation .navigation.navigation ul li.donate,
#navigation .topbar.navigation ul li.donate {
  background: #d71683;
}
#navigation .navigation.navigation ul li.donate a,
#navigation .topbar.navigation ul li.donate a {
  color: #fff;
  padding-left: 40px;
}
#navigation .navigation.navigation ul li.donate a:hover,
#navigation .topbar.navigation ul li.donate a:hover {
  background: #ea369c;
}
#navigation .navigation.navigation ul li.donate .icon,
#navigation .topbar.navigation ul li.donate .icon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0.625rem;
  top: 50%;
  margin-top: -12px;
  font-size: 1.5rem;
}
@media only screen and (max-width: 991px) {
  #navigation .navigation ul,
  #navigation .topbar ul {
    display: block !important;
  }
  #navigation .navigation ul li,
  #navigation .topbar ul li {
    display: block !important;
    padding: 0;
    position: relative;
  }
  #navigation .navigation ul li:before,
  #navigation .topbar ul li:before {
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    position: absolute;
    bottom: 0;
    background: #0079a6;
  }
  #navigation .navigation ul li a,
  #navigation .topbar ul li a {
    padding: 0 1.25rem !important;
    line-height: 3em !important;
    font-size: 0.875rem !important;
    text-align: left;
    color: #ffffff !important;
  }
  #navigation .navigation ul li a:hover,
  #navigation .topbar ul li a:hover {
    background: #0079a6;
  }
  #navigation .navigation ul li.donate a,
  #navigation .topbar ul li.donate a {
    padding-left: 50px !important;
  }
}
.subnavigation ul li,
.common-blog-aside-categories ul li,
.common-blog-aside-tags ul li {
  display: block;
  margin-bottom: 0.3125rem !important;
}
.subnavigation ul li a,
.common-blog-aside-categories ul li a,
.common-blog-aside-tags ul li a,
.common-blog-aside-categories .tags a,
.common-blog-aside-tags .tags a {
  background: #fff;
  border: 1px solid #009ed9;
  border-radius: 3px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 0.625rem;
  font-size: 0.6875rem;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  display: inline-block;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  color: #009ed9;
  display: block;
  text-align: left;
  font-size: 0.8125rem;
  position: relative;
  line-height: 36px;
}
.subnavigation ul li a:hover,
.common-blog-aside-categories ul li a:hover,
.common-blog-aside-tags ul li a:hover {
  background: #009ed9;
  color: #fff;
}
.subnavigation ul li a i,
.common-blog-aside-categories ul li a i,
.common-blog-aside-tags ul li a i {
  font-size: 0.6875rem;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.page-donate .subnavigation ul li a,
.page-donate .common-blog-aside-categories ul li a,
.page-donate .common-blog-aside-tags ul li a {
  color: #d71683;
  border-color: #d71683;
}
.page-donate .subnavigation ul li a:hover,
.page-donate .common-blog-aside-categories ul li a:hover,
.page-donate .common-blog-aside-tags ul li a:hover {
  background: #d71683;
  color: #fff;
}
.page-donate .subnavigation ul li a:hover i,
.page-donate .common-blog-aside-categories ul li a:hover i,
.page-donate .common-blog-aside-tags ul li a:hover i {
  color: #fff;
}
.subnavigation ul li a i,
.common-blog-aside-categories ul li a i,
.common-blog-aside-tags ul li a i {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 0.875rem;
  margin-top: -7px;
  color: #000;
}
html {
  font-family: 'Oxygen', sans-serif;
  font-weight: 400;
  font-weight: 100;
  font-size: 16px;
  min-height: 100%;
}
body {
  min-height: 100%;
  background: #ffffff;
  color: #002534;
}
#mainx {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7f7f7+0,ffffff+100 */
  background: #eff7fa;
  /* Old browsers */
  background: -moz-linear-gradient(top, #eff7fa 0%, #ffffff 50%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #eff7fa 0%, #ffffff 50%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #eff7fa 0%, #ffffff 50%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eff7fa', endColorstr='#ffffff', GradientType=0.5);
  /* IE6-9 */
}
#wrapper > section {
  position: relative;
  -webkit-transform: translateZ(0px);
  -moz-transform: translateZ(0px);
  -o-transform: translateZ(0px);
  -ms-transform: translateZ(0px);
  transform: translateZ(0px);
}
.breadcrumbs {
  font-size: 0.6875rem;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
  margin-bottom: 1.25rem;
}
@media only screen and (max-width: 640px) {
  .breadcrumbs {
    display: none;
  }
}
.breadcrumbs i {
  color: #aaa;
  font-size: 0.5rem;
  width: 10px;
}
.breadcrumbs i:first-child {
  font-size: 0.75rem;
  color: #009ed9;
}
.breadcrumbs #breadcrumbs {
  max-width: 1100px;
  margin: auto !important;
  padding: 0 2.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  overflow: hidden;
  height: auto;
}
.breadcrumbs #breadcrumbs a {
  color: #009ed9;
}
.breadcrumbs #breadcrumbs span,
.breadcrumbs #breadcrumbs a,
.breadcrumbs #breadcrumbs i {
  line-height: 20px;
  float: left;
  padding: 0;
}
.page-home .breadcrumbs {
  display: none;
}
#header {
  position: relative;
  overflow: hidden;
  background: #000000;
}
#header > .wrapper-content {
  position: relative;
  min-height: 300px;
}
#header .icon {
  position: absolute;
  bottom: 40px;
  left: -60px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 5rem;
  color: #fff;
  z-index: 10;
}
@media only screen and (max-width: 640px) {
  #header .icon {
    display: none;
  }
}
#header .title {
  position: absolute;
  bottom: 0;
  left: 20%;
  z-index: 10;
  font-size: 1.25rem;
  text-transform: uppercase;
  color: #006188;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 400;
  display: table;
}
@media only screen and (max-width: 640px) {
  #header .title {
    display: block;
    left: 1.25rem !important;
  }
}
#header .title > span {
  display: table-cell;
  vertical-align: bottom;
}
@media only screen and (max-width: 640px) {
  #header .title > span {
    display: block;
  }
}
#header .title > span > span {
  display: inline-block;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.3125rem;
  font-size: 1.25rem;
}
@media only screen and (max-width: 640px) {
  #header .title > span:first-child > span {
    position: relative;
  }
}
#header .title > span:nth-child(even) span {
  font-size: 3.125rem;
  line-height: 50px;
  margin-left: -1px;
}
.page-donate #header .title {
  color: #d71683;
}
.page-home #header > .wrapper-content {
  min-height: 400px;
  height: 80vh;
}
.page-home #header .title {
  display: block;
  bottom: 50%;
  right: 0;
  left: 50%;
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -o-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}
.page-home #header .title > span {
  display: block;
  margin-bottom: 2px;
  vertical-align: bottom;
}
.page-home #header .title > span > span {
  background-color: transparent;
  text-shadow: 0px 0px 8px #ffffff;
}
#header .background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #009ed9;
  background-size: cover;
}
#footer {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7f7f7+0,ffffff+100 */
  background: #f7f7f7;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f7f7f7 0%, #ffffff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f7f7f7 0%, #ffffff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f7f7f7 0%, #ffffff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f7f7', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
  padding-top: 1.25rem;
}
@media only screen and (max-width: 991px) {
  #footer {
    padding: 0;
  }
}
#footer > .wrapper-content {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: auto !important;
  padding: 0 2.5rem;
}
#footer > .wrapper-content li {
  padding: 0;
  margin: 0;
}
#footer > .wrapper-content > div {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
@media only screen and (max-width: 640px) {
  #footer > .wrapper-content {
    padding: 1.25rem;
  }
}
#footer .col-internal {
  width: 70%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#footer .col-internal li {
  padding: 0;
  margin: 0;
}
#footer .col-internal > div {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
@media only screen and (max-width: 991px) {
  #footer .col-internal {
    width: 100%;
  }
}
#footer .col-external {
  width: 30%;
  padding-left: 0.625rem;
}
@media only screen and (max-width: 991px) {
  #footer .col-external {
    width: 100%;
  }
}
#footer .partners ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  padding: 0.3125rem 0;
  margin-right: -0.3125rem;
}
#footer .partners ul li {
  padding: 0;
  margin: 0;
}
#footer .partners ul li {
  width: calc(50% -  0.3125rem);
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
  float: left;
  border: 1px solid #eee;
}
@media only screen and (max-width: 991px) {
  #footer .partners ul li {
    width: calc(33.33% -  0.3125rem);
  }
}
#footer .partners ul li strong {
  display: none;
}
#footer .partners ul li a img {
  display: block;
  width: 100%;
}
#footer .address {
  width: 50%;
  background: #ffffff;
  padding: 1.25rem;
  color: #006188;
  position: relative;
}
#footer .address:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: rgba(0, 0, 0, 0.05);
}
#footer .address h6 {
  font-size: 1.5rem;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
}
#footer .address h6 i {
  margin-right: 5px;
}
#footer .address .phone {
  font-size: 2.25rem;
}
#footer .address .phone i {
  font-size: 0.6em;
}
@media only screen and (max-width: 991px) {
  #footer .address {
    width: 50%;
  }
}
@media only screen and (max-width: 640px) {
  #footer .address {
    width: 100%;
    text-align: center;
  }
}
#footer .donate {
  width: 50%;
  background: #d71683;
  padding: 1.25rem;
  text-align: center;
  color: #fff;
  position: relative;
  -webkit-animation: blinker 2s linear infinite;
  -moz-animation: blinker 2s linear infinite;
  -o-animation: blinker 2s linear infinite;
  animation: blinker 2s linear infinite;
}
#footer .donate:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: rgba(0, 0, 0, 0.05);
}
#footer .donate h6 {
  font-size: 0.9375rem;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
}
#footer .donate h6 i {
  margin-right: 5px;
}
#footer .donate p {
  font-size: 1.5rem;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  text-transform: uppercase;
}
#footer .donate p span {
  display: block;
  font-size: 2.25rem;
  font-family: 'Oxygen', sans-serif;
  font-weight: 400;
}
@media only screen and (max-width: 991px) {
  #footer .donate {
    width: 50%;
  }
}
@media only screen and (max-width: 640px) {
  #footer .donate {
    width: 100%;
    text-align: center;
  }
}
#footer .links {
  width: 100%;
  padding: 1.25rem;
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#footer .links li {
  padding: 0;
  margin: 0;
}
#footer .links > div {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
#footer .links > div {
  width: 33.33%;
  padding: 1.25rem;
}
@media only screen and (max-width: 640px) {
  #footer .links > div {
    width: 100%;
  }
}
#footer .links > div h6 {
  font-size: 1.125rem;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  text-transform: uppercase;
  color: #009ed9;
  margin-bottom: 10px;
  margin-top: 1.25rem;
}
#footer .links > div ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#footer .links > div ul li {
  padding: 0;
  margin: 0;
}
#footer .links > div ul li a {
  display: block;
  color: #666;
  font-size: 0.875rem;
  line-height: 20px;
  cursor: pointer;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
}
#footer .links > div ul li:hover a {
  color: #006188;
  text-decoration: underline;
}
#footer .copyright {
  background: #fff;
  width: 100%;
  border-top: 4px solid #009ed9;
  margin-top: 0.625rem;
}
#footer .copyright > .wrapper-content {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: auto !important;
  padding: 0 2.5rem;
  overflow: hidden;
  padding: 0.625rem 0;
  display: table;
  width: 100%;
  text-transform: uppercase;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
}
#footer .copyright > .wrapper-content li {
  padding: 0;
  margin: 0;
}
#footer .copyright > .wrapper-content > div {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
@media only screen and (max-width: 640px) {
  #footer .copyright > .wrapper-content {
    padding: 1.25rem;
  }
}
#footer .copyright .site {
  color: #999;
  display: table-cell;
  vertical-align: middle;
}
#footer .copyright .urls {
  color: #999;
  display: table-cell;
  vertical-align: middle;
}
#footer .copyright .maven {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  font-size: 0.625rem;
}
#footer .copyright .maven span {
  display: inline-block;
  line-height: 20px;
  margin-right: 5px;
}
#footer .copyright .maven a {
  color: #999;
}
#footer .copyright .maven svg {
  height: 12px;
  top: 2px;
  position: relative;
}
#footer .copyright .maven svg path {
  fill: #002534;
}
.content h1,
.content h2,
.content h3,
.main-content .introduction h1,
.main-content .introduction h2,
.main-content .introduction h3 {
  font-size: 1.5rem;
  color: #002534;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  position: relative;
  padding-bottom: 0.625rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;
  margin-top: 2.5rem;
}
.content h1:after,
.content h2:after,
.content h3:after,
.main-content .introduction h1:after,
.main-content .introduction h2:after,
.main-content .introduction h3:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 2px;
  width: 1.25rem;
  background: #006188;
  border-radius: 4px;
}
.content h1 span,
.content h2 span,
.content h3 span,
.main-content .introduction h1 span,
.main-content .introduction h2 span,
.main-content .introduction h3 span {
  display: block;
  font-weight: 400;
  font-size: 0.8em;
}
.content h1:first-child,
.content h2:first-child,
.content h3:first-child,
.main-content .introduction h1:first-child,
.main-content .introduction h2:first-child,
.main-content .introduction h3:first-child {
  margin-top: 0;
}
.content h1 ~ h2,
.content h1 ~ h3,
.main-content .introduction h1 ~ h2,
.main-content .introduction h1 ~ h3 {
  margin-bottom: 0.625rem;
}
.content h1 ~ h2:after,
.content h1 ~ h3:after,
.main-content .introduction h1 ~ h2:after,
.main-content .introduction h1 ~ h3:after {
  display: none;
}
.content h3,
.main-content .introduction h3 {
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
  color: #006188;
}
.content h3:after,
.main-content .introduction h3:after {
  display: none;
}
.content .address,
.content .ccp,
.main-content .introduction .address,
.main-content .introduction .ccp {
  padding: 1.25rem;
  border: 1px solid #009ed9;
  margin-bottom: 1.25rem;
  color: #009ed9;
}
.content .ccp,
.main-content .introduction .ccp {
  border-color: #d71683;
  color: #d71683;
  font-size: 1.375rem;
}
.content .ccp strong,
.main-content .introduction .ccp strong {
  font-size: 0.875rem;
}
.content ul,
.main-content .introduction ul {
  font-size: 0.9375rem;
  max-width: 600px;
  margin-bottom: 1.875rem;
}
.content ul li,
.main-content .introduction ul li {
  margin-bottom: 0.625rem;
  line-height: 1.5em;
}
.content iframe,
.main-content .introduction iframe {
  width: 100%;
  border: 10px solid #fff;
  margin: 1.25rem auto !important;
  display: block;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
}
.content form iframe,
.main-content .introduction form iframe {
  border: 0;
  margin: 0;
  box-shadow: none;
}
.content p,
.main-content .introduction p {
  max-width: 600px;
  font-size: 0.9375rem;
}
.content p img,
.main-content .introduction p img,
.media-campaigns li img,
.common-blog-galleries .gallery-image ul li a,
.common-blog-article header figure img,
.common-blog .common-blog-listing article header figure img {
  border: 10px solid #fff;
  margin: 1.25rem auto !important;
  display: block;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
}
.content p img.none,
.main-content .introduction p img.none {
  box-shadow: none;
  border: 0;
  background: none;
}
.content p.headline,
.content p strong,
.main-content .introduction p.headline,
.main-content .introduction p strong {
  font-weight: 700;
}
.content p a,
.main-content .introduction p a {
  color: #009ed9;
  text-decoration: underline;
}
.content p img,
.main-content .introduction p img,
.media-campaigns li img,
.common-blog-galleries .gallery-image ul li a,
.common-blog-article header figure img,
.common-blog .common-blog-listing article header figure img {
  max-width: 90%;
  margin: auto;
}
.block.know {
  background: #fafafa;
}
.block.know > .wrapper-content > div {
  padding: 1.875rem 0;
}
.block.know h2 {
  color: #d71683;
}
.block.know h2:after {
  display: none;
}
.main-content:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.main-content > .wrapper {
  max-width: 1100px;
  margin: auto !important;
  padding: 0 2.5rem;
}
.main-content > .wrapper:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
@media only screen and (max-width: 640px) {
  .main-content > .wrapper {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -moz-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .main-content > .wrapper li {
    padding: 0;
    margin: 0;
  }
  .main-content > .wrapper > div {
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }
}
.main-content section,
.main-content aside {
  margin-bottom: 1.875rem;
}
@media only screen and (max-width: 640px) {
  .main-content section,
  .main-content aside {
    padding: 1.25rem;
    margin: 0;
    float: none !important;
    width: 100% !important;
    display: block;
  }
}
.main-content aside {
  float: right;
  width: calc(45% -  1.875rem);
  margin-left: 1.875rem;
}
@media only screen and (max-width: 640px) {
  .main-content aside {
    width: 100%;
    float: none;
    -webkit-box-ordinal-group: 20;
    -moz-box-ordinal-group: 20;
    -ms-flex-order: 20;
    -webkit-order: 20;
    order: 20;
    padding: 0;
    margin: 0;
  }
}
.main-content aside ~ section {
  clear: left;
  width: 55%;
}
@media only screen and (max-width: 640px) {
  .main-content aside ~ section {
    width: 100%;
    float: none;
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
}
.main-content .introduction {
  width: 55%;
  float: left;
}
.main-content .block,
.main-content #section-actions,
.main-content #section-sponsors {
  width: 100%;
  float: none;
}
.main-content #section-sponsors > .wrapper-content {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  position: relative;
}
@media only screen and (max-width: 640px) {
  .main-content #section-sponsors > .wrapper-content {
    padding: 0 !important;
  }
}
.main-content #section-sponsors > .wrapper-content ul li {
  margin-bottom: 0.625rem;
}
.main-content #section-sponsors > .wrapper-content ul li a {
  border: 1px solid #eee;
}
.main-content #section-sponsors .view-all {
  background: #fff;
  border: 1px solid #009ed9;
  border-radius: 3px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 0.625rem;
  font-size: 0.6875rem;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  display: inline-block;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  color: #009ed9;
  position: absolute;
  right: 2.5rem;
  top: 1.875rem;
  color: #d71683;
  border-color: #d71683;
}
.main-content #section-sponsors .view-all:hover {
  background: #009ed9;
  color: #fff;
}
.main-content #section-sponsors .view-all i {
  font-size: 0.6875rem;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.page-donate .main-content #section-sponsors .view-all {
  color: #d71683;
  border-color: #d71683;
}
.page-donate .main-content #section-sponsors .view-all:hover {
  background: #d71683;
  color: #fff;
}
.page-donate .main-content #section-sponsors .view-all:hover i {
  color: #fff;
}
.main-content #section-sponsors .view-all:hover {
  background: #d71683;
}
.block.stretch > .wrapper-content {
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.block > .wrapper-content {
  max-width: 1100px;
  margin: auto !important;
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 2.5rem;
}
.block > .wrapper-content li {
  padding: 0;
  margin: 0;
}
.block > .wrapper-content > div {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
@media only screen and (max-width: 640px) {
  .block > .wrapper-content {
    padding: 0;
  }
}
.block > .wrapper-content > div {
  padding-right: 1.875rem;
}
.block > .wrapper-content > div.content,
.block > .wrapper-content > div.main-content .introduction {
  width: 60%;
}
.block > .wrapper-content > div.content + div,
.block > .wrapper-content > div.main-content .introduction + div {
  width: 40%;
}
.block > .wrapper-content > div img {
  max-width: 100%;
}
.block > .wrapper-content > div.w-main {
  width: 60%;
}
.block > .wrapper-content > div.w-side {
  width: 40%;
}
.block > .wrapper-content > div.w-33 {
  width: 33.33%;
}
.block > .wrapper-content > div.w-25 {
  width: 25%;
}
.block > .wrapper-content > div.w-20 {
  width: 20%;
}
.block > .wrapper-content > div:last-child {
  padding-right: 0;
  padding-left: 1.875rem;
}
@media only screen and (max-width: 640px) {
  .block > .wrapper-content > div {
    display: block;
    float: none;
    width: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 1.25rem !important;
  }
}
section.actions > .wrapper-content {
  max-width: 1100px;
  margin: auto !important;
  padding: 0 2.5rem;
}
@media only screen and (max-width: 640px) {
  section.actions > .wrapper-content {
    padding: 0;
  }
}
.page-home section.actions > .wrapper-content {
  height: 120px;
}
@media only screen and (max-width: 640px) {
  .page-home section.actions > .wrapper-content {
    height: auto;
  }
}
.page-actions section.actions > .wrapper-content {
  padding: 0.625rem;
}
section.actions ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  z-index: 100;
}
section.actions ul li {
  padding: 0;
  margin: 0;
}
section.actions ul li {
  padding: 0;
  margin: 0;
}
section.actions ul > div {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.page-home section.actions ul {
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -o-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
}
@media only screen and (max-width: 640px) {
  .page-home section.actions ul {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}
section.actions ul li {
  width: calc(25% - 2px);
  background: none no-repeat center;
  background-image: url('../images/bg-scratch.png');
  background-size: 90% auto;
  margin-right: 2px;
  padding: 1.25rem;
  padding-bottom: 1.875rem;
  position: relative;
  cursor: pointer;
  color: #fff;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
@media only screen and (max-width: 640px) {
  section.actions ul li {
    padding: 0.625rem;
  }
}
section.actions ul li .image {
  font-size: 3.75rem;
  display: block;
  margin: auto;
  text-align: center;
  line-height: 80px;
}
@media only screen and (max-width: 640px) {
  section.actions ul li .image {
    position: absolute;
    top: 0.625rem;
    left: 0.625rem;
    font-size: 2.5rem;
    line-height: 40px;
  }
}
.page-home section.actions ul li {
  color: #fff !important;
}
.page-home section.actions ul li:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: rgba(0, 0, 0, 0.2);
}
.page-actions section.actions ul li,
.page-donate section.actions ul li {
  background-color: transparent !important;
}
.page-actions section.actions ul li:after,
.page-donate section.actions ul li:after {
  content: "";
  position: absolute;
  right: 0;
  height: 50%;
  width: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #009ed9;
  opacity: 0.3;
}
.page-actions section.actions ul li:last-child:after,
.page-donate section.actions ul li:last-child:after {
  display: none;
}
section.actions ul li:last-child {
  margin-right: 0;
  width: calc(25%);
}
@media only screen and (max-width: 640px) {
  section.actions ul li {
    width: 100% !important;
    margin: 0;
  }
  section.actions ul li:hover,
  section.actions ul li:after {
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -o-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
  }
  section.actions ul li .arrow {
    display: none;
  }
}
.page-home section.actions ul li .arrow {
  position: absolute;
  width: 1px;
  height: 1px;
  top: 0;
  left: 50%;
  border: 15px solid transparent;
  opacity: 0;
  margin-left: -15px;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  display: none;
}
.page-home section.actions ul li:hover {
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
}
.page-home section.actions ul li:hover .arrow {
  opacity: 1;
  -webkit-transform: translateY(-30px);
  -moz-transform: translateY(-30px);
  -o-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
}
.page-actions section.actions ul li:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
section.actions ul li img {
  width: 70%;
  display: block;
  margin: auto;
  opacity: 0.6;
}
section.actions ul li .more {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1.25rem;
  margin: 1.25rem;
}
section.actions ul li .title {
  font-size: 1.25rem;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
@media only screen and (max-width: 640px) {
  section.actions ul li .title {
    text-align: left;
    padding-left: 3.125rem;
  }
}
section.actions ul li .title span:last-child {
  display: block;
  font-weight: 400;
  font-size: 0.8em;
  opacity: 0.6;
}
.donation-sponsor {
  border-top: 1px solid #eee;
}
.donation-sponsor > .wrapper-content {
  max-width: 1100px;
  margin: auto !important;
  padding: 0 2.5rem;
}
.donation-sponsor h2 {
  font-size: 1.5rem;
  color: #002534;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  position: relative;
  padding-bottom: 0.625rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;
  color: #d71683;
}
.donation-sponsor h2:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 2px;
  width: 1.25rem;
  background: #006188;
  border-radius: 4px;
}
.donation-sponsor h2 span {
  display: block;
  font-weight: 400;
  font-size: 0.8em;
}
.donation-sponsor h2:after {
  background: #d71683;
}
.donation-sponsor ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-right: -0.625rem;
  overflow: hidden;
}
.donation-sponsor ul li {
  padding: 0;
  margin: 0;
}
.donation-sponsor ul li {
  float: left;
  width: calc(16.66% -  0.625rem);
  margin-right: 0.625rem;
}
@media only screen and (max-width: 640px) {
  .donation-sponsor ul li {
    margin-right: 0;
    width: 50%;
  }
  .donation-sponsor ul li a {
    border: 0 !important;
  }
}
.donation-sponsor ul li a {
  display: block;
}
.donation-sponsor ul li img {
  width: 100%;
  display: block;
}
.news-homepage {
  background: #fafafa;
  padding: 0.625rem;
  position: relative;
}
.news-homepage .view-all {
  background: #fff;
  border: 1px solid #009ed9;
  border-radius: 3px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 0.625rem;
  font-size: 0.6875rem;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  display: inline-block;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  color: #009ed9;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
}
.news-homepage .view-all:hover {
  background: #009ed9;
  color: #fff;
}
.news-homepage .view-all i {
  font-size: 0.6875rem;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.page-donate .news-homepage .view-all {
  color: #d71683;
  border-color: #d71683;
}
.page-donate .news-homepage .view-all:hover {
  background: #d71683;
  color: #fff;
}
.page-donate .news-homepage .view-all:hover i {
  color: #fff;
}
.news-homepage > h2 {
  font-size: 1.125rem;
  color: #002534;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  position: relative;
  padding-bottom: 0.625rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;
  margin: 0.625rem;
  margin-bottom: 2.5rem;
}
.news-homepage > h2:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 2px;
  width: 2.5rem;
  background: #006188;
  border-radius: 4px;
}
.news-homepage > h2 span {
  display: block;
  font-weight: 400;
  font-size: 0.8em;
  color: #009ed9;
}
.news-homepage ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.news-homepage ul li {
  padding: 0;
  margin: 0;
}
.news-homepage ul li {
  display: block;
  margin-bottom: 0.625rem !important;
}
.news-homepage ul li:last-child {
  margin-bottom: 0 !important;
}
.news-homepage ul li article {
  position: relative;
  min-height: calc(100px +  1.25rem);
  padding: 0.625rem;
}
.news-homepage ul li article:hover {
  background: #fff;
}
.news-homepage ul li article header {
  padding-left: 120px;
}
.news-homepage ul li article .more {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0.625rem;
  color: #009ed9;
  cursor: pointer;
}
.news-homepage ul li article time {
  position: absolute;
  top: 50%;
  left: 0.625rem;
  width: 100px;
  text-align: center;
  line-height: 35px;
  transform: translateY(-50%);
}
.news-homepage ul li article time span {
  display: block;
  color: #fff;
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
}
.news-homepage ul li article .title {
  text-transform: uppercase;
  color: #006188;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
}
.news-homepage ul li article p {
  font-size: 0.8125rem;
}
.news-homepage ul li article figure {
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  width: 100px;
}
.news-homepage ul li article figure img {
  display: block;
  width: 100%;
}
.slider-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.slider-header div,
.slider-header ul,
.slider-header li {
  height: 100%;
}
.slider-header ul {
  position: relative;
  margin: 0;
  padding: 0;
}
.slider-header ul li {
  background-size: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}
.slider-header ul li:first-child {
  opacity: 1;
  z-index: 10;
}
@media only screen and (max-width: 640px) {
  .slider-header ul li:first-child {
    background-position: center;
  }
}
[rel*="action"] .slider-header ul li:first-child {
  opacity: 0;
}
.action_vacances #action_vacances {
  opacity: 1;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.action_fourniture #action_fourniture {
  opacity: 1;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.action_parrainage #action_parrainage {
  opacity: 1;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.action_contribution #action_contribution {
  opacity: 1;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.slider-homepage {
  margin-bottom: 2.5rem;
  padding: 0 0.625rem;
  position: relative;
}
.slider-homepage .view-all {
  background: #fff;
  border: 1px solid #009ed9;
  border-radius: 3px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 0.625rem;
  font-size: 0.6875rem;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  display: inline-block;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  color: #009ed9;
  position: absolute;
  right: 1.25rem;
  top: 0;
}
.slider-homepage .view-all:hover {
  background: #009ed9;
  color: #fff;
}
.slider-homepage .view-all i {
  font-size: 0.6875rem;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.page-donate .slider-homepage .view-all {
  color: #d71683;
  border-color: #d71683;
}
.page-donate .slider-homepage .view-all:hover {
  background: #d71683;
  color: #fff;
}
.page-donate .slider-homepage .view-all:hover i {
  color: #fff;
}
.slider-homepage > h2 {
  font-size: 1.125rem;
  color: #002534;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  position: relative;
  padding-bottom: 0.625rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;
  margin: 0 0.625rem;
  margin-bottom: 2.5rem;
}
.slider-homepage > h2:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 2px;
  width: 2.5rem;
  background: #006188;
  border-radius: 4px;
}
.slider-homepage > h2 span {
  display: block;
  font-weight: 400;
  font-size: 0.8em;
  color: #009ed9;
}
.slider-homepage > div {
  position: relative;
}
.slider-homepage > div:before,
.slider-homepage > div:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(218, 224, 227, 0.4);
  -webkit-transform: scale(1.05) rotate(-1deg) translateX(5px);
  -moz-transform: scale(1.05) rotate(-1deg) translateX(5px);
  -o-transform: scale(1.05) rotate(-1deg) translateX(5px);
  -ms-transform: scale(1.05) rotate(-1deg) translateX(5px);
  transform: scale(1.05) rotate(-1deg) translateX(5px);
}
.slider-homepage > div:after {
  -webkit-transform: scale(1.05) rotate(1deg) translateX(-5px);
  -moz-transform: scale(1.05) rotate(1deg) translateX(-5px);
  -o-transform: scale(1.05) rotate(1deg) translateX(-5px);
  -ms-transform: scale(1.05) rotate(1deg) translateX(-5px);
  transform: scale(1.05) rotate(1deg) translateX(-5px);
}
.slider-homepage .lSAction > a {
  background: #fff;
  opacity: 1;
  display: none;
}
.slider-homepage .lSAction > .lSPrev {
  left: -20px;
}
.slider-homepage .lSAction > .lSNext {
  right: -20px;
}
.slider-homepage .lSPager {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 100;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.slider-homepage .lSSlideOuter {
  background: #fff;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 20;
}
.slider-homepage ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.slider-homepage ul li {
  padding: 0;
  margin: 0;
}
.slider-homepage ul li article {
  position: relative;
}
.slider-homepage ul li article img {
  display: block;
}
.slider-homepage ul li article header {
  position: absolute;
  bottom: 40px;
  left: 0;
  color: #fff;
  padding: 1.25rem;
}
.slider-homepage ul li article header time {
  background: rgba(0, 158, 217, 0.8);
  display: inline-block;
  padding: 4px 8px;
  font-size: 0.6875rem;
}
.slider-homepage ul li article header p {
  display: none;
}
.slider-homepage ul li article header .title {
  display: block;
  margin-top: 1px;
  font-size: 1.625rem;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
}
.slider-homepage ul li article header .title a {
  display: inline-block;
  background: rgba(0, 158, 217, 0.8);
  padding: 4px 8px;
}
.page-home .block > .wrapper-content > div {
  width: 50%;
}
.page-home iframe {
  width: 100%;
  border: 10px solid #fff;
  margin: 1.25rem auto !important;
  display: block;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 640px) {
}
.page-interior .about-comite {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.page-interior .about-comite li {
  padding: 0;
  margin: 0;
}
.page-interior .about-comite li {
  padding: 0;
  margin: 0;
}
.page-interior .about-comite > div {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.page-interior .about-comite li {
  width: 50%;
  text-align: center;
  padding: 1.25rem !important;
}
.page-interior .about-comite li figure {
  width: 100%;
  margin: auto;
  overflow: hidden;
  border-radius: 50%;
  border: 10px solid #eee;
  margin-bottom: 1.25rem;
}
.page-interior .about-comite li figure img {
  width: 100%;
  display: block;
}
.page-interior .about-comite li span {
  display: block;
  text-align: center;
}
.page-interior .about-comite li span.name {
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  font-size: 1.25rem;
}
.page-interior .about-comite li span.role {
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  color: #009ed9;
}
.page-interior .back {
  font-size: 0.8125rem;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  color: #009ed9;
  text-transform: uppercase;
  display: block;
  margin-bottom: 1.25rem;
}
@media only screen and (max-width: 640px) {
  .page-interior .back {
    margin-top: 1.25rem;
  }
}
.page-interior .back i {
  font-size: 0.5625rem;
}
.page-interior .back + h1,
.page-interior .back + h2 {
  margin-top: 0;
}
.page-interior.page-donate .back {
  color: #d71683;
}
.page-interior .action-icon {
  background: none no-repeat top center;
  background-image: url('../images/bg-scratch.png');
  background-size: 100% 100%;
  height: 300px;
  position: relative;
  margin-bottom: 1.25rem;
}
.page-interior .action-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  font-size: 6.25rem;
  color: #006188;
}
.page-interior .certification-zewo {
  background: #fff;
  margin-bottom: 0;
}
.page-interior .certification-zewo img {
  width: 60px;
  float: left;
  display: block;
  margin-right: 1.25rem;
}
.page-interior .certification-zewo a {
  display: block;
  overflow: hidden;
}
.page-interior .certification-zewo h3 {
  float: left;
}
.page-interior .certification-zewo p {
  clear: both;
}
.page-interior aside > section {
  background: #f7f7f7;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
}
.page-interior aside > section h3 {
  font-size: 1.5rem;
  color: #002534;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  position: relative;
  padding-bottom: 0.625rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;
  font-size: 1.125rem;
}
.page-interior aside > section h3:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 2px;
  width: 1.25rem;
  background: #006188;
  border-radius: 4px;
}
.page-interior aside > section h3 span {
  display: block;
  font-weight: 400;
  font-size: 0.8em;
}
.page-interior aside > section p,
.page-interior aside > section li {
  font-size: 0.8125rem;
}
.page-interior aside > section .local-business .contact {
  margin-top: 1.25rem;
}
.page-interior aside > section .local-business .contact .phone,
.page-interior aside > section .local-business .contact .fax {
  font-size: 1.25rem;
}
.page-interior aside > section .ask-for-help,
.page-interior aside > section .ask-for-donation,
.page-interior aside > section .contact-us {
  background: #009ed9;
  margin: -1.25rem;
  margin-top: 1.25rem;
  padding: 1.25rem;
  padding-left: 80px;
  padding-right: 60px;
  position: relative;
  cursor: pointer;
  display: block;
  color: #ffffff;
}
.page-interior aside > section .ask-for-help.ask-for-donation,
.page-interior aside > section .ask-for-donation.ask-for-donation,
.page-interior aside > section .contact-us.ask-for-donation {
  background: #d71683;
}
.page-interior aside > section .ask-for-help p a,
.page-interior aside > section .ask-for-donation p a,
.page-interior aside > section .contact-us p a {
  color: #ffffff;
}
.page-interior aside > section .ask-for-help i.help,
.page-interior aside > section .ask-for-donation i.help,
.page-interior aside > section .contact-us i.help {
  font-size: 2.5rem;
  position: absolute;
  left: 20px;
  top: 20px;
  color: #ffffff;
}
.page-interior aside > section .ask-for-help i.arrow,
.page-interior aside > section .ask-for-donation i.arrow,
.page-interior aside > section .contact-us i.arrow {
  font-size: 1.25rem;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -10px;
  color: #ffffff;
}
.page-interior aside > section .ask-for-help h3,
.page-interior aside > section .ask-for-donation h3,
.page-interior aside > section .contact-us h3 {
  color: #ffffff;
  margin: 0;
}
.page-interior aside > section .ask-for-help h3:after,
.page-interior aside > section .ask-for-donation h3:after,
.page-interior aside > section .contact-us h3:after {
  display: none;
}
.page-interior .block:nth-child(1) > div > div:nth-child(1) {
  width: 60%;
}
.page-interior .block:nth-child(1) > div > div:nth-child(2) {
  width: 40%;
}
.page-donate aside > section {
  background: #fbd8ec;
  background: #f7f7f7;
}
.page-donate aside > section h3:after {
  background: #d71683;
}
.page-donation strong {
  color: #d71683;
}
.donation-persons {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
.donation-persons li {
  padding: 0;
  margin: 0;
}
.donation-persons li {
  width: 50%;
  float: left;
  position: relative;
  padding-left: 50px !important;
}
@media only screen and (max-width: 640px) {
  .donation-persons li {
    width: 100%;
    float: none;
    margin-bottom: 0.625rem !important;
    display: block;
  }
}
.donation-persons li figure {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  background: #f7f7f7;
  line-height: 40px;
  text-align: center;
}
.donation-persons li .name {
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 400;
  font-weight: 500;
  color: #006188;
  font-size: 1rem;
  display: block;
}
.donation-persons li .region {
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  display: block;
  font-size: 0.875rem;
  margin-top: -2px;
}
.donation-companies {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
.donation-companies li {
  padding: 0;
  margin: 0;
}
.donation-companies li {
  width: 33.33%;
  float: left;
  position: relative;
}
@media only screen and (max-width: 640px) {
  .donation-companies li {
    width: 50%;
  }
}
.donation-companies li a {
  display: block;
}
.donation-companies li img {
  width: 100%;
  display: block;
}
p + .donation-form,
p + .contact-form {
  margin-top: 2.5rem;
}
.donation-form .paypal,
.contact-form .paypal {
  width: 120px;
  height: 40px;
  float: left;
  padding-top: 8px;
}
.donation-form .paypal img,
.contact-form .paypal img {
  width: 100%;
}
.donation-form > .donation-form-online,
.contact-form > .donation-form-online,
.donation-form > .donation-form-virement,
.contact-form > .donation-form-virement,
.donation-form > .contact-form-online,
.contact-form > .contact-form-online {
  padding: 2.5rem;
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 640px) {
  .donation-form > .donation-form-online,
  .contact-form > .donation-form-online,
  .donation-form > .donation-form-virement,
  .contact-form > .donation-form-virement,
  .donation-form > .contact-form-online,
  .contact-form > .contact-form-online {
    padding: 1.25rem;
  }
}
.donation-form > .donation-form-online .area,
.contact-form > .donation-form-online .area,
.donation-form > .donation-form-virement .area,
.contact-form > .donation-form-virement .area,
.donation-form > .contact-form-online .area,
.contact-form > .contact-form-online .area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 10;
  cursor: pointer;
}
.donation-form > .donation-form-online:hover,
.contact-form > .donation-form-online:hover,
.donation-form > .donation-form-virement:hover,
.contact-form > .donation-form-virement:hover,
.donation-form > .contact-form-online:hover,
.contact-form > .contact-form-online:hover {
  background: #e7f8ff;
}
.donation-form > .donation-form-online .icon,
.contact-form > .donation-form-online .icon,
.donation-form > .donation-form-virement .icon,
.contact-form > .donation-form-virement .icon,
.donation-form > .contact-form-online .icon,
.contact-form > .contact-form-online .icon {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
}
.donation-form > .donation-form-online .rdo,
.contact-form > .donation-form-online .rdo,
.donation-form > .donation-form-virement .rdo,
.contact-form > .donation-form-virement .rdo,
.donation-form > .contact-form-online .rdo,
.contact-form > .contact-form-online .rdo {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid #333;
}
.donation-form > .donation-form-online .rdo:after,
.contact-form > .donation-form-online .rdo:after,
.donation-form > .donation-form-virement .rdo:after,
.contact-form > .donation-form-virement .rdo:after,
.donation-form > .contact-form-online .rdo:after,
.contact-form > .contact-form-online .rdo:after {
  position: absolute;
  top: 0;
  left: 0;
  background: #d71683;
  width: 14px;
  height: 14px;
  margin: 4px;
  border-radius: 50%;
  content: "";
  display: none;
}
.donation-form > .donation-form-online .form,
.contact-form > .donation-form-online .form,
.donation-form > .donation-form-virement .form,
.contact-form > .donation-form-virement .form,
.donation-form > .contact-form-online .form,
.contact-form > .contact-form-online .form {
  display: none;
}
.donation-form > .donation-form-online.active,
.contact-form > .donation-form-online.active,
.donation-form > .donation-form-virement.active,
.contact-form > .donation-form-virement.active,
.donation-form > .contact-form-online.active,
.contact-form > .contact-form-online.active {
  background: #e7f8ff;
}
.donation-form > .donation-form-online.active .rdo:after,
.contact-form > .donation-form-online.active .rdo:after,
.donation-form > .donation-form-virement.active .rdo:after,
.contact-form > .donation-form-virement.active .rdo:after,
.donation-form > .contact-form-online.active .rdo:after,
.contact-form > .contact-form-online.active .rdo:after {
  display: block;
}
.donation-form > .donation-form-online.active .icon,
.contact-form > .donation-form-online.active .icon,
.donation-form > .donation-form-virement.active .icon,
.contact-form > .donation-form-virement.active .icon,
.donation-form > .contact-form-online.active .icon,
.contact-form > .contact-form-online.active .icon {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.donation-form > .donation-form-online.active .form,
.contact-form > .donation-form-online.active .form,
.donation-form > .donation-form-virement.active .form,
.contact-form > .donation-form-virement.active .form,
.donation-form > .contact-form-online.active .form,
.contact-form > .contact-form-online.active .form {
  display: block;
}
.donation-form > .donation-form-online h2,
.contact-form > .donation-form-online h2,
.donation-form > .donation-form-virement h2,
.contact-form > .donation-form-virement h2,
.donation-form > .contact-form-online h2,
.contact-form > .contact-form-online h2 {
  margin-top: 0;
}
.donation-form > .donation-form-online h3,
.contact-form > .donation-form-online h3,
.donation-form > .donation-form-virement h3,
.contact-form > .donation-form-virement h3,
.donation-form > .contact-form-online h3,
.contact-form > .contact-form-online h3 {
  margin: 0;
  padding: 0;
}
.donation-form > .donation-form-online h3:after,
.contact-form > .donation-form-online h3:after,
.donation-form > .donation-form-virement h3:after,
.contact-form > .donation-form-virement h3:after,
.donation-form > .contact-form-online h3:after,
.contact-form > .contact-form-online h3:after {
  display: none;
}
.donation-form > .donation-form-online p,
.contact-form > .donation-form-online p,
.donation-form > .donation-form-virement p,
.contact-form > .donation-form-virement p,
.donation-form > .contact-form-online p,
.contact-form > .contact-form-online p {
  position: relative;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.2em;
  padding-bottom: 0.625rem;
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
}
.donation-form > .donation-form-online p:after,
.contact-form > .donation-form-online p:after,
.donation-form > .donation-form-virement p:after,
.contact-form > .donation-form-virement p:after,
.donation-form > .contact-form-online p:after,
.contact-form > .contact-form-online p:after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  border-radius: 4px;
  width: 20px;
  content: "";
  background: #d71683;
}
.msg {
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  position: relative;
}
.msg .close {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  margin: 0.625rem;
  z-index: 10;
  cursor: pointer;
}
.msg .close:hover {
  background: #fff;
}
.msg strong {
  font-size: 1.5rem;
  color: #002534;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  position: relative;
  padding-bottom: 0.625rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;
  color: #d71683;
  display: block;
  margin-bottom: 0.625rem !important;
}
.msg strong:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 2px;
  width: 1.25rem;
  background: #006188;
  border-radius: 4px;
}
.msg strong span {
  display: block;
  font-weight: 400;
  font-size: 0.8em;
}
.msg strong:after {
  display: none;
}
.msg.success {
  background: #e9f4c6;
}
.msg.success strong {
  color: #8fb322;
}
.msg.error {
  background: #fde9ee;
}
.form {
  overflow: hidden;
}
.form .g-recaptcha {
  margin-top: 0.625rem;
  padding-left: 100px;
}
@media only screen and (max-width: 640px) {
  .form .g-recaptcha {
    padding: 0;
  }
}
.form .field {
  clear: both;
  display: table;
  width: 100%;
  margin-top: 0.625rem;
}
.form .field > label {
  display: table-cell;
  line-height: 30px;
  font-size: 0.875rem;
  white-space: nowrap;
  width: 100px;
  padding-right: 10px;
  vertical-align: top;
  padding-top: 8px;
  text-align: right;
}
@media only screen and (max-width: 991px) {
  .form .field > label {
    display: block;
    width: 100%;
    text-align: left;
  }
}
@media only screen and (max-width: 640px) {
  .form .field > label {
    display: block;
    width: 100%;
    text-align: left;
  }
}
.form .field .input {
  display: table-cell;
  padding: 0.3125rem;
  background: #ffffff;
}
@media only screen and (max-width: 991px) {
  .form .field .input {
    display: block;
    width: 100%;
  }
}
@media only screen and (max-width: 640px) {
  .form .field .input {
    display: block;
    width: 100%;
  }
}
.form .field .input input,
.form .field .input textarea,
.form .field .input select {
  border: 0;
  background: none;
  line-height: 30px;
  font-size: 1.125rem;
  width: 100%;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 400;
}
.form .field.radio {
  padding-top: 1.25rem;
}
.form .field.radio > .input {
  background: none;
}
.form .field.radio > label {
  display: block;
}
.form .field.radio .input {
  display: block;
  overflow: hidden;
  border: 0;
  padding: 0.625rem;
  padding-bottom: 0;
}
.form .field.radio .input input[type="radio"],
.form .field.radio .input input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.form .field.radio .input span {
  display: block;
  padding-left: 30px;
  font-size: 0.75rem;
}
.form .field.radio .input span i {
  font-weight: 700;
  font-style: normal;
  font-size: 1.125rem;
}
.form .field.radio .input span:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #333;
}
.form .field.radio .input span:before {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #d71683;
  width: 14px;
  height: 14px;
  margin: 4px;
  border-radius: 50%;
  content: "";
  display: none;
  z-index: 10;
}
.form .field.radio .input input:checked + span:before {
  display: block;
}
.form .field.radio .input label {
  float: left;
  width: 33.33%;
  position: relative;
  padding: 10px;
}
@media only screen and (max-width: 991px) {
  .form .field.radio .input label {
    float: none;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .form .field.radio .input label span {
    line-height: 40px;
    padding-left: 40px;
  }
}
@media only screen and (max-width: 640px) {
  .form .field.radio .input label {
    float: none;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .form .field.radio .input label span {
    line-height: 40px;
    padding-left: 40px;
  }
}
.form .field.radio .input label:hover {
  background: rgba(255, 255, 255, 0.5);
}
.form footer {
  padding-top: 1.25rem;
  display: block;
  clear: both;
  text-align: right;
}
.form .btn {
  background: #d71683;
  color: #fff;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 400;
  text-align: center;
  line-height: 34px;
  padding: 0 1.25rem;
  border-radius: 4px;
  border: 0;
  font-size: 1.125rem;
  text-transform: uppercase;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}
@media only screen and (max-width: 640px) {
  .form .btn {
    display: block;
    width: 100%;
  }
}
.form .btn:hover {
  background: #a91167;
}
.form .field[rel='city'] {
  width: 62%;
  float: right;
  clear: none;
}
.form .field[rel='zipcode'] {
  width: 36%;
  float: left;
}
.media-campaigns {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-right: -1.25rem !important;
}
.media-campaigns li {
  padding: 0;
  margin: 0;
}
.media-campaigns li {
  float: left;
  width: calc(33.33% -  1.25rem);
  position: relative;
  margin-right: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}
.media-campaigns li img {
  display: block;
}
.media-campaigns li h3 {
  margin-bottom: 0 !important;
}
.media-campaigns li.horizontal {
  width: calc(100% -  1.25rem);
}
.page-blog-article #header > .wrapper-content {
  min-height: 200px;
}
.page-blog-article #header > .wrapper-content h1 {
  display: none;
}
@media only screen and (max-width: 640px) {
  .page-blog-article {
    padding: 1.25rem !important;
  }
  .page-blog-article #header {
    display: none;
  }
}
.common-blog-aside-categories,
.common-blog-aside-tags {
  float: none !important;
  width: auto !important;
  margin-bottom: 1.25rem;
  background: #f9f9f9 !important;
  overflow: hidden;
  padding: 1.25rem;
}
@media only screen and (max-width: 640px) {
  .common-blog-aside-categories,
  .common-blog-aside-tags {
    padding: 1.25rem !important;
  }
}
.common-blog-aside-categories .title,
.common-blog-aside-tags .title {
  font-size: 1.5rem;
  color: #002534;
  font-family: 'Dosis', sans-serif;
  font-weight: 300;
  font-weight: 700;
  position: relative;
  padding-bottom: 0.625rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;
  font-size: 1.125rem;
}
.common-blog-aside-categories .title:after,
.common-blog-aside-tags .title:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 2px;
  width: 1.25rem;
  background: #006188;
  border-radius: 4px;
}
.common-blog-aside-categories .title span,
.common-blog-aside-tags .title span {
  display: block;
  font-weight: 400;
  font-size: 0.8em;
}
.common-blog-aside-categories p,
.common-blog-aside-tags p,
.common-blog-aside-categories li,
.common-blog-aside-tags li {
  font-size: 0.8125rem;
}
.common-blog-aside-categories ul,
.common-blog-aside-tags ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.common-blog-aside-categories ul li,
.common-blog-aside-tags ul li {
  padding: 0;
  margin: 0;
}
.common-blog-aside-categories ul li.active a,
.common-blog-aside-tags ul li.active a {
  background: #009ed9;
  color: #fff;
}
.common-blog-aside-categories .tags,
.common-blog-aside-tags .tags {
  overflow: hidden;
}
.common-blog-aside-categories .tags a,
.common-blog-aside-tags .tags a {
  float: left;
  margin-right: 4px;
  margin-bottom: 4px;
  background: none;
  border-color: #aaa;
  color: #aaa;
  padding: 0 10px;
  line-height: 20px;
  font-size: 0.75rem;
}
.common-blog-galleries > div {
  margin-bottom: 1.875rem;
}
.common-blog-galleries > div ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.common-blog-galleries > div ul li {
  padding: 0;
  margin: 0;
}
.common-blog-galleries .gallery-image ul {
  overflow: hidden;
  margin: 0;
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 0 !important;
  background: #f7f7f7;
}
.common-blog-galleries .gallery-image ul li {
  float: left;
  width: calc(25% - 10px);
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}
@media only screen and (max-width: 640px) {
  .common-blog-galleries .gallery-image ul li {
    float: left;
    width: calc(50% - 10px);
  }
}
.common-blog-galleries .gallery-image ul li span {
  display: none;
}
.common-blog-galleries .gallery-image ul li a {
  display: block;
  margin: 0 !important;
  max-width: 100%;
}
.common-blog-galleries .gallery-image ul li a img {
  width: 100%;
  display: block;
}
.common-blog-article {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
.common-blog-article li {
  padding: 0;
  margin: 0;
}
.common-blog-article > div {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.common-blog-article .common-blog-content {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
  border-bottom: 1px solid #eee;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
}
.common-blog-article .common-blog-content h2,
.common-blog-article .common-blog-content h3,
.common-blog-article .common-blog-content h4,
.common-blog-article .common-blog-content h1 {
  text-transform: none;
  margin: 1.25rem 0 0.3125rem 0;
  font-weight: 600;
}
.common-blog-article .common-blog-content h2:after,
.common-blog-article .common-blog-content h3:after,
.common-blog-article .common-blog-content h4:after,
.common-blog-article .common-blog-content h1:after {
  display: none;
}
.common-blog-article .common-blog-galleries {
  -webkit-box-ordinal-group: 3;
  -moz-box-ordinal-group: 3;
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}
.common-blog-article .categories {
  -webkit-box-ordinal-group: 4;
  -moz-box-ordinal-group: 4;
  -ms-flex-order: 4;
  -webkit-order: 4;
  order: 4;
}
.common-blog-article .categories ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.common-blog-article .categories ul li {
  padding: 0;
  margin: 0;
}
.common-blog-article .categories ul li a {
  display: inline-block;
  list-style: none;
  text-transform: uppercase;
  padding: 2px 6px;
  margin-right: 2px !important;
  margin-bottom: 2px !important;
  font-size: 11px;
  border-radius: 3px;
  background: #eee;
}
.common-blog-article .tags {
  -webkit-box-ordinal-group: 5;
  -moz-box-ordinal-group: 5;
  -ms-flex-order: 5;
  -webkit-order: 5;
  order: 5;
  margin-top: 1.25rem;
}
.common-blog-article .tags ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.common-blog-article .tags ul li {
  padding: 0;
  margin: 0;
}
.common-blog-article .tags ul li a {
  display: inline-block;
  list-style: none;
  text-transform: uppercase;
  padding: 2px 6px;
  margin-right: 2px !important;
  margin-bottom: 2px !important;
  font-size: 11px;
  border-radius: 3px;
  background: #eee;
}
.common-blog-article .pagination-navigation {
  position: absolute;
  top: 3.125rem;
  right: 0;
  display: none;
}
.common-blog-article .pagination-navigation .btn-previous,
.common-blog-article .pagination-navigation .btn-next {
  float: left;
  width: 40px;
  height: 40px;
  background: #f7f7f7;
  margin-left: 0.625rem;
  text-align: center;
  line-height: 36px;
  opacity: 0.2;
  border-radius: 3px;
  cursor: pointer;
}
.common-blog-article .pagination-navigation .btn-previous span,
.common-blog-article .pagination-navigation .btn-next span {
  display: none;
}
.common-blog-article .pagination-navigation .btn-previous.btn-next,
.common-blog-article .pagination-navigation .btn-next.btn-next {
  float: right;
}
.common-blog-article .pagination-navigation a {
  opacity: 1 !important;
}
.common-blog-article .pagination-navigation a:hover {
  background: #009ed9;
  color: #fff;
}
.common-blog-article .share {
  -webkit-box-ordinal-group: 5;
  -moz-box-ordinal-group: 5;
  -ms-flex-order: 5;
  -webkit-order: 5;
  order: 5;
  padding: 0;
  border-top: 1px solid #eee;
  padding-top: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  background: none;
  overflow: hidden;
}
.common-blog-article .share .social-sharing {
  float: right;
}
.common-blog-article header {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.common-blog-article header li {
  padding: 0;
  margin: 0;
}
.common-blog-article header > div {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.common-blog-article header figure {
  -webkit-box-ordinal-group: 3;
  -moz-box-ordinal-group: 3;
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
  margin-bottom: 1.25rem;
}
.common-blog-article header figure img {
  margin: 0!important;
}
.common-blog-article header .date {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
  font-size: 0.8em;
  color: #aaa;
  margin-bottom: 1.25rem;
}
.common-blog-article header .date time,
.common-blog-article header .date a {
  color: #002534;
}
.common-blog-article header .date .author:before {
  content: "|";
  display: inline-block;
  margin: 0 4px;
}
.common-blog-article header .title {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
  margin: 0;
  margin-bottom: 1.25rem;
  text-transform: none;
  font-weight: 600;
  font-size: 3.125rem;
}
.common-blog > .wrapper-content {
  max-width: 1100px;
  margin: auto !important;
  padding: 0 2.5rem;
}
@media only screen and (max-width: 640px) {
  .common-blog > .wrapper-content {
    padding: 0;
  }
}
.common-blog .common-blog-listing {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1.25rem;
}
.common-blog .common-blog-listing li {
  padding: 0;
  margin: 0;
}
.common-blog .common-blog-listing li {
  padding: 0;
  margin: 0;
}
.common-blog .common-blog-listing > div {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.common-blog .common-blog-listing > li {
  width: 100%;
  border-bottom: 1px solid #009ed9;
}
.common-blog .common-blog-listing > li:last-child {
  border: 0;
}
.common-blog .common-blog-listing > li article {
  padding-left: 140px;
}
@media only screen and (max-width: 640px) {
  .common-blog .common-blog-listing > li article {
    padding-left: 120px;
    padding-right: 20px;
  }
}
.common-blog .common-blog-listing > li article header .title {
  font-size: 1.375rem;
}
.common-blog .common-blog-listing > li article header figure {
  position: absolute;
  top: 1.25rem;
  left: 0;
  height: 100px;
  width: 100px;
  background: #f7f7f7;
}
.common-blog .common-blog-listing > li.highlight {
  width: 100%;
}
.common-blog .common-blog-listing > li.highlight article {
  padding-left: 0;
}
.common-blog .common-blog-listing > li.highlight article header .title {
  font-size: 1.875rem;
}
.common-blog .common-blog-listing > li.highlight article header figure {
  position: relative;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  background: #f7f7f7;
}
.common-blog .common-blog-listing article {
  position: relative;
  padding: 1.25rem 0 0.625rem 0;
}
.common-blog .common-blog-listing article .categories {
  display: none;
}
.common-blog .common-blog-listing article .common-blog-galleries ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.common-blog .common-blog-listing article .common-blog-galleries ul li {
  padding: 0;
  margin: 0;
}
.common-blog .common-blog-listing article header {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.common-blog .common-blog-listing article header li {
  padding: 0;
  margin: 0;
}
.common-blog .common-blog-listing article header > div {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.common-blog .common-blog-listing article header figure {
  -webkit-box-ordinal-group: 3;
  -moz-box-ordinal-group: 3;
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
  margin-bottom: 1.25rem;
}
.common-blog .common-blog-listing article header figure img {
  margin: 0 !important;
  max-width: 100%;
  width: 100%;
}
.common-blog .common-blog-listing article header .date {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
  font-size: 0.8em;
  color: #aaa;
  margin-bottom: 0.625rem;
}
.common-blog .common-blog-listing article header .date time,
.common-blog .common-blog-listing article header .date a {
  color: #002534;
}
.common-blog .common-blog-listing article header .date .author:before {
  content: "|";
  display: inline-block;
  margin: 0 4px;
}
.common-blog .common-blog-listing article header .title {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
  margin: 0;
  margin-bottom: 1.25rem;
  text-transform: none;
  font-weight: 600;
  font-size: 1.875rem;
}
.common-blog .common-blog-listing article .common-blog-content,
.common-blog .common-blog-listing article .common-blog-resume {
  overflow: hidden;
  position: relative;
}
.common-blog .common-blog-listing article .common-blog-content p:last-child,
.common-blog .common-blog-listing article .common-blog-resume p:last-child {
  margin-top: 1.25rem;
}
.common-blog .common-blog-listing article .common-blog-more {
  float: right;
}
@media only screen and (max-width: 640px) {
  .common-blog .common-blog-listing article .common-blog-more {
    float: none;
  }
}
.common-blog .common-blog-listing article .common-blog-more i {
  display: none;
}
.common-blog .common-blog-listing article .common-blog-more a {
  font-size: 0.8125rem;
  color: #009ed9;
  text-decoration: none;
}
.social-sharing {
  font-weight: 400;
  float: left;
  margin-left: -5px;
  /*================ Large Buttons ================*/
  /*================ Clean Buttons ================*/
}
.social-sharing * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.social-sharing a {
  display: inline-block;
  color: #fff;
  border-radius: 2px;
  margin: 0 5px 0px 0;
  height: 22px;
  line-height: 22px;
  text-decoration: none;
  font-weight: normal;
}
.social-sharing a:hover {
  color: #fff;
}
.social-sharing span {
  display: inline-block;
  vertical-align: top;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
}
.social-sharing .icon {
  padding: 0 5px 0 10px;
}
.social-sharing .icon:before {
  line-height: 22px;
}
.social-sharing.is-large a {
  height: 44px;
  line-height: 44px;
}
.social-sharing.is-large a span {
  height: 44px;
  line-height: 44px;
  font-size: 18px;
}
.social-sharing.is-large a .icon {
  padding: 0 10px 0 18px;
}
.social-sharing.is-large a .icon:before {
  line-height: 44px;
}
.social-sharing .share-title {
  font-weight: 900;
  font-size: 12px;
  padding-right: 10px;
}
.social-sharing .is-large .share-title {
  padding-right: 16px;
}
.social-sharing .share-facebook {
  background-color: #3b5998;
}
.social-sharing .share-facebook:hover {
  background-color: #2d4373;
}
.social-sharing .share-twitter {
  background-color: #00aced;
}
.social-sharing .share-twitter:hover {
  background-color: #0087ba;
}
.social-sharing .share-pinterest {
  background-color: #cb2027;
}
.social-sharing .share-pinterest:hover {
  background-color: #9f191f;
}
.social-sharing .share-fancy {
  background-color: #4999dc;
}
.social-sharing .share-fancy:hover {
  background-color: #2780cb;
}
.social-sharing .share-google {
  background-color: #dd4b39;
}
.social-sharing .share-google:hover {
  background-color: #c23321;
}
.social-sharing .share-reddit {
  background-color: #5f99cf;
}
.social-sharing .share-reddit:hover {
  background-color: #3a80c1;
}
.social-sharing .share-linkedin {
  background-color: #0077b5;
}
.social-sharing .share-linkedin:hover {
  background-color: #005582;
}
.social-sharing .share-skype {
  background-color: #00aff0;
}
.social-sharing .share-skype:hover {
  background-color: #008abd;
}
.social-sharing .share-count {
  position: relative;
  background-color: #fff;
  padding: 0 8px;
  margin-right: -2px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  border-radius: 0 2px 2px 0;
  border: 1px solid #ececec;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
}
.social-sharing .share-count.is-loaded {
  opacity: 1;
}
.social-sharing .share-count:before,
.social-sharing .share-count:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid;
}
.social-sharing .share-count:before {
  left: -6px;
  border-right-color: #ececec;
}
.social-sharing .share-count:after {
  left: -5px;
  border-right-color: #fff;
}
.social-sharing a:hover .share-count {
  background-color: whitesmoke;
}
.social-sharing a:hover .share-count:after {
  border-right-color: whitesmoke;
}
.social-sharing .is-large .share-count {
  font-size: 18px;
  padding: 0 14px;
}
.social-sharing .social-sharing.is-clean a {
  background-color: #fff;
  border: 1px solid #ececec;
  color: #333;
  height: 30px;
  line-height: 30px;
}
.social-sharing .social-sharing.is-clean a span {
  height: 30px;
  line-height: 30px;
  font-size: 13px;
}
.social-sharing .social-sharing.is-clean a:hover {
  background-color: #ececec;
}
.social-sharing .social-sharing.is-clean a:hover .share-count {
  background-color: #fff;
}
.social-sharing .social-sharing.is-clean a:hover .share-count:after {
  border-right-color: #fff;
}
.social-sharing .social-sharing.is-clean a .share-title {
  font-weight: normal;
}
.social-sharing .social-sharing.is-clean .share-count {
  top: -1px;
}
.social-sharing .social-sharing.is-clean .icon-facebook {
  color: #3b5998;
}
.social-sharing .social-sharing.is-clean .icon-twitter {
  color: #00aced;
}
.social-sharing .social-sharing.is-clean .icon-pinterest {
  color: #cb2027;
}
.social-sharing .social-sharing.is-clean .icon-fancy {
  color: #4999dc;
}
.social-sharing .social-sharing.is-clean .icon-google {
  color: #dd4b39;
}
.social-sharing .social-sharing.is-clean .icon-reddit {
  color: #5f99cf;
}
.social-sharing .social-sharing.is-clean .icon-linkedin {
  color: #0077b5;
}
.social-sharing .social-sharing.is-clean .icon-skype {
  color: #00aff0;
}
.social-sharing .social-sharing.is-clean [class*="icon-"] {
  color: #333 !important;
}
.social-sharing .social-sharing.is-clean a:hover {
  background-color: #333;
  color: #fff !important;
}
.social-sharing .social-sharing.is-clean a:hover [class*="icon-"] {
  color: #fff !important;
}
@keyframes blinker {
  0% {
    background: #d71683;
  }
  50% {
    background: #a91167;
  }
  100% {
    background: #d71683;
  }
}
