body{
    #page{
        .animate;
    }
    @media @breakpoint-tablet {
        .mobile-navigation-opened & {
            overflow: hidden;

            #page #wrapper,
            #page #navigation{
                .animate;
                .lh-transform(translateX(-250px));
            }
        }
    }
}

#navigation{
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 1000;

    .mobile-navigation-opened & {
        @media @breakpoint-tablet {
            bottom: 0;
        }
    }

    > .wrapper-content{
        .wrapper-content();
        background: rgba(255,255,255,0.95);
        border-top: 4px solid @color-brand;
        display: table;
        width: 100%;
        min-height: 100px;


        @media @breakpoint-tablet{
            min-height: 60px;
            border: 0px;
            background: rgba(255,255,255,1);
            box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
            padding-left: @gutter;
        }

    }

    .mobile-navigation{
        position: absolute;
        right: @gutter*2;
        top: 30px;
        width: 40px;
        height: 40px;
        margin-top: -20px;
        display: none;

        span{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            line-height: 40px;
            .animate;
        }
        span.icon-close{
            opacity: 0;
        }
        span.icon-menu{
            font-size: 20px;
        }

        @media @breakpoint-tablet{
            display: block;
        }

        .mobile-navigation-opened & {
            span.icon-close{
                opacity: 1;
            }
            span.icon-menu{
                opacity: 0;
            }
        }

    }

    .navigation-container{
        display: table-cell;
        vertical-align: bottom;
        text-align: right;

        @media @breakpoint-tablet{
            display: block;
            position: absolute;
            top: 0;
            left: 100%;
            width: 250px;
            bottom: 0;
            background: @color-brand;
            overflow-y: scroll; /* has to be scroll, not auto */
            -webkit-overflow-scrolling: touch;
            //box-shadow: 5px 0px 2px darken(@color-brand, 10%) inset;
        }
    }

    .logo{
        width: 25%;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        //padding-left: @gutterMargin;

        @media @breakpoint-tablet{
            width: auto;
            display: block;
            margin-top: @gutter;
            max-width: 190px;
        }

        a{
            display: block;
            width: 90%;
            margin: auto;
        }
        img{
            width: 100%;
            display: block;
        }
        strong{
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }
    }


    .navigation,
    .topbar{

        &.topbar{
            ul{
                li{
                    a{
                        line-height: 50px;
                        .font-special();
                        .font-regular();
                        .size(14);
                        text-transform: uppercase;
                        color: @color-brand-secondary;
                        padding: 0 @gutter;
                    }

                    &.facebook{
                        @media @breakpoint-tablet{
                            display: none !important;
                        }
                    }
                }
            }
        }

        &.navigation{
            ul{
                display:table;
                //width:100%;
                float: right;

                @media @breakpoint-tablet{
                    float: none;
                }

                li{
                    display:table-cell;
                    position: relative;
                    text-align: center;

                    a{
                        line-height: 60px;
                        .font-special();
                        .font-regular();
                        .size(18);
                        text-transform: uppercase;
                        color: @color-brand;
                        padding: 0 @gutter;
                    }
                    &:hover{
                        background: #fff;
                    }
                    &.active{
                        a{
                            .font-bold();
                            color: @color-brand-secondary;
                        }
                        &:after{
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            height: 2px;
                            background: @color-brand-secondary;
                        }
                    }
                    &.donate{
                        background: @color-brand-donation;
                        a{
                            color:#fff;
                            padding-left: 40px;

                            &:hover{
                                background: lighten(@color-brand-donation, 10%);
                            }
                        }

                        .icon{
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            left: @gutter;
                            top: 50%;
                            margin-top: -12px;
                            .size(24);
                        }
                    }
                }
            }
        }


        @media @breakpoint-tablet{
           ul{
               display: block !important;
               li{
                   display: block !important;
                   padding: 0;
                   position: relative;
                   &:before{
                       content: "";
                       height: 1px;
                       width: 100%;
                       left: 0;
                       position: absolute;
                       bottom: 0;
                       background: darken(@color-brand, 10%);
                   }
                   a{
                       padding: 0 @gutter*2 !important;
                       line-height: 3em !important;
                       .size(14) !important;
                       text-align: left;
                       color: @color-white !important;

                       &:hover{
                           background: darken(@color-brand, 10%);
                       }
                   }
                   &.donate{
                       a{
                           padding-left: 50px !important;
                       }
                   }
               }
           }
        }
    }
}


.subnavigation{
    ul{
        li{
            display: block;
            margin-bottom: @gutter/2 !important;
            a{
                .button();
                display: block;
                text-align: left;
                .size(13);
                position: relative;
                line-height: 36px;

                i{
                    position: absolute;
                    top: 50%;
                    right: 0;
                    .size(14);
                    margin-top: -7px;
                    color: #000;
                }
            }
        }
    }
}